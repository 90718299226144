import store from "@/services/store";
import i18nService from "@/services/i18n";

export interface HeaderData {
    year?: number;
    dataType: number;
    abp: number;
    gu: IGu;
    finalAbp?: number;
    regional?: RegionalData;
}

export interface RegionalData {
    abp: number;
    gu: IGu;
    dataType: number;
}

export interface DataType {
    id: number;
    code: string;
    name_ru: string;
    name_kk: string;
    beg_date: any;
    end_date: any;
    name: string;
}

export interface IGr {
    id: number;
    gr: number;
    pgr?: number;
    abp?: number;
    prg?: number;
    ppr?: number;
    type: number;
    name_ru: string;
    name_kk: string;
    short_name_ru: string;
    short_name_kk: string;
    beg_date: string;
    end_date: any;
    full_code: string;
    budget_level_id?: number;
    name: string;
}

export interface IPgr {
    idArr: number[];
    id: number;
    gr: number;
    pgr: number;
    abp?: number;
    prg?: number;
    ppr?: number;
    type: number;
    name_ru: string;
    name_kk: string;
    short_name_ru: any;
    short_name_kk: any;
    beg_date: any;
    end_date: any;
    full_code: string;
    budget_level_id: number;
    name: string;
}

export interface IAbp {
    idArr: number[];
    id: number;
    gr: number;
    pgr: number;
    abp: number;
    prg?: number;
    ppr?: number;
    type: number;
    name_ru: string;
    name_kk: string;
    short_name_ru: any;
    short_name_kk: any;
    beg_date: any;
    end_date: any;
    full_code: string;
    budget_level_id: number;
    name: string;
}

/** DEPRECATED
 * export interface Prg {
 *     idArr: number[];
 *     id: number;
 *     gr: number;
 *     pgr: number;
 *     abp: number;
 *     prg: number;
 *     ppr?: number;
 *     type: number;
 *     name_ru: string;
 *     name_kk: string;
 *     short_name_ru: any;
 *     short_name_kk: any;
 *     beg_date: any;
 *     end_date: any;
 *     full_code: string;
 *     budget_level_id?: number;
 *     name: string;
 *     develop_type?: number;
 * }
 */

/** DEPRECATED
 * export interface Ppr {
 *     idArr: number[];
 *     id: number;
 *     gr: number;
 *     pgr: number;
 *     abp: number;
 *     prg: number;
 *     ppr: number;
 *     type: number;
 *     name_ru: string;
 *     name_kk: string;
 *     short_name_ru: any;
 *     short_name_kk: any;
 *     beg_date: any;
 *     end_date: any;
 *     full_code: string;
 *     budget_level_id?: number;
 *     name: string;
 * }
 */

export interface IPrg {
    gr: number;
    pgr: number;
    abp: number;
    prg: number;
    ppr?: number;
    pprList?: IPpr[];
    type: number;
    develop_type?: number;
    name_ru: string;
    name_kk: string;
    text: string;
    budget_level_id?: number[];
}

export interface IPpr {
    gr: number;
    pgr: number;
    abp: number;
    prg: number;
    ppr: number;
    type: number;
    develop_type?: number;
    name_ru: string;
    name_kk: string;
    budget_level_id?: number[] | number;
    source?: string;
}

export interface IGu {
    id: number;
    code: string;
    source_id: string;
    summary_flag: string;
    name_ru: string;
    name_kk: string;
    gu_bin: string;
    gu_rnn?: string;
    id_region: number;
    id_budget_type: string;
    address: string;
    beg_date: string;
    end_date: string;
    kato: string;
    budget_type: string;
    rucov: string; // Руководитель
    buhgal: string; // Бухгалтер
    abp_owner: string;
    name: string;
}

export interface IBipProjectData {
    /** Код БИПа */
    code: string; // Код

    /** Наименование на русском */
    nameRu: string; // Рус.наименование

    /** Наименование на казахском */
    nameKk: string; // Каз. наименование

    /** Заголовок проекта с информацией о ГУ */
    header: any;

    /** Тип объекта */
    object: string;

    /** Тип проекта */
    projectType: string;

    /**  Гос. программы */
    governmentProgram: IGovProgram[];

    /** Регион ГУ */
    region_budget: string;

    /** Регион конечного ГУ */
    district_budget: string;

    /** Код като района */
    region: string;

    /** Объект населенного пункта по като */
    localityObject: ILocalityObject | null;

    /** Экономическая внутренняя норма доходности (EIRR) */
    directCash: number;

    /** Сумма запроса ЦТ из РБ, тыс. тенге */
    considerationGA: number;

    /** Общая стоимость */
    totalCost: number;

    /** Стоимость без ПИР и ГЭ */
    costWithout: number;

    /** прямые денежные притоки */
    isDirectCash: boolean;

    /** Направлено на рассмотрение в ЦГО */
    isConsiderationCentralGovernment: boolean; //

    /** Период */
    period: ProjectPeriod; //

    /** modellingData */
    modellingData: ModellingData;

    /** перечень прикрепленных файлов */
    files: any[]; //

    /** Код инициатора */
    variant: string;

    /** Код получателя инвестиций */
    variant_recipient: string;

    /** check */
    check: boolean;
}

export interface ILocalityObject {
    id: number;
    code: string;
    par_id: number;
    name_kz: string;
    name_ru: string;
    date_end: string | null;
    date_begin: string;
    type_of_settlement: string;
}

export interface IGovProgram {
    /** id программы */
    id: number;

    /** kato_stat_id программы */
    kato_stat_id: number;

    /** parent_id программы */
    parent_id: number | null;

    /** Код программы */
    code: string;

    /** Код */
    code_kato: string;

    /** Описание */
    description: string;

    /** Дата создания */
    create_date: string;

    /** Дата начала */
    start_date: string;

    /** Дата обновления */
    update_date: string;

    /** Дата конца */
    end_date: string;

    /** Модуль */
    module: string;

    /** Наименование на английском */
    name_en: string;

    /** Наименование на казахском */
    name_kz: string;

    /** Наименование на русском */
    name_ru: string;
}

export interface BipDict {
    id: number;
    code?: string;
    nameRu: string;
    nameKk?: string;
}

export interface IFallbackFindItemResponse {
    code: null;
    name_ru: string;
    name_kz: string;
    name_en: string;
}

export type ProjectType = BipDict;

export type ProjectStatus = BipDict;

export const ProjectStatusList: ProjectStatus[] = [
    { id: 1, code: "1", nameRu: "Заявка" },
    { id: 2, code: "2", nameRu: "На рассмотрении" },
    { id: 3, code: "3", nameRu: "Рассмотрено УЭБП. Поддержано" },
    { id: 4, code: "4", nameRu: "Рассмотрено УЭБП. Отказано" },
    { id: 5, code: "5", nameRu: "Рассмотрено ОБК. Поддержано" },
    { id: 6, code: "6", nameRu: "Рассмотрено ОБК. Отказано" },
    { id: 7, code: "7", nameRu: "Реализуется" },
];

export const ProjectRealizingList: ProjectRealizing[] = [
    {
        id: 1,
        code: "1",
        nameRu: "Развитие объектов образования",
        nameKk: "Білім беру объектілерін дамыту",
    },
    {
        id: 2,
        code: "2",
        nameRu: "Развитие объектов здравоохранения",
        nameKk: "Денсаулық сақтау объектілерін дамыту",
    },
    {
        id: 3,
        code: "3",
        nameRu: "Развитие социальных объектов",
        nameKk: "Әлеуметтік нысандардың дамуы",
    },
    {
        id: 4,
        code: "4",
        nameRu: "Развитие объектов спорта",
        nameKk: "Спорт ғимараттарын дамыту",
    },
    {
        id: 5,
        code: "5",
        nameRu: "Развитие объектов культуры",
        nameKk: "Мәдени объектілерді дамыту",
    },
    {
        id: 6,
        code: "6",
        nameRu: "Развитие теплоэнергетической системы",
        nameKk: "Жылу -энергетикалық жүйенің дамуы",
    },
    {
        id: 7,
        code: "7",
        nameRu: "Развитие системы водообеспечения в городах",
        nameKk: "Қалаларда сумен жабдықтау жүйесін дамыту",
    },
    {
        id: 8,
        code: "8",
        nameRu: "Развитие системы водообеспечения в сельских населенных пунктах",
        nameKk: "Ауылдық жерлерде сумен жабдықтау жүйесін дамыту",
    },
    {
        id: 9,
        code: "9",
        nameRu: "Жилищное строительство: жилье",
        nameKk: "Тұрғын үй құрылысы: тұрғын үй",
    },
    {
        id: 10,
        code: "10",
        nameRu: "Жилищное строительство: Инженерно-коммуникационные сети к объектам жилищного строительства",
        nameKk: "Тұрғын үй құрылысы: тұрғын үй құрылысы объектілеріне инженерлік -коммуникациялық желілер",
    },
    {
        id: 11,
        code: "11",
        nameRu: "Реализация мероприятий в рамках Программы «Дорожная карта бизнеса 2025»",
        nameKk: "«Бизнестің жол картасы 2025» Бағдарламасы аясындағы іс -шараларды жүзеге асыру",
    },
    {
        id: 12,
        code: "12",
        nameRu: "Реализация мероприятий в моногородах",
        nameKk: "Моноқалалардағы оқиғаларды жүзеге асыру",
    },
    {
        id: 13,
        code: "13",
        nameRu: "Развитие инженерной инфраструктуры в рамках Программы развития регионов до 2025 года",
        nameKk: "Өңірлерді дамытудың 2025 жылға дейінгі бағдарламасы аясында инженерлік инфрақұрылымды дамыту",
    },
    {
        id: 14,
        code: "14",
        nameRu: "Охрана окружающей среды",
        nameKk: "Қоршаған ортаны қорғау",
    },
    {
        id: 15,
        code: "15",
        nameRu: "Развитие инфраструктуры специальных экономических зон, индустриальных зон, индустриальных парков",
        nameKk: "Арнайы экономикалық аймақтардың, индустриалды аймақтардың, индустриалды парктердің инфрақұрылымын дамыту",
    },
    {
        id: 16,
        code: "16",
        nameRu: "Развитие объектов мобилизационной подготовки и чрезвычайных ситуаций",
        nameKk: "Жұмылдыру дайындығы мен төтенше жағдайлардың құралдарын дамыту",
    },
    {
        id: 17,
        code: "17",
        nameRu: "Развитие коммунального хозяйства",
        nameKk: "Коммуналдық шаруашылықты дамыту",
    },
    {
        id: 18,
        code: "18",
        nameRu: "Орошение",
        nameKk: "Суару",
    },
    {
        id: 19,
        code: "19",
        nameRu: "Развитие транспортной инфраструктуры",
        nameKk: "Көлік инфрақұрылымын дамыту",
    },
    {
        id: 20,
        code: "20",
        nameRu: 'Развитие социальной и инженерной инфраструктуры в сельских населенных пунктах в рамках проекта "Ауыл-Ел бесігі"',
        nameKk: "«Ауыл-Ел бесігі» жобасы аясында ауылдық елді мекендердің әлеуметтік және инженерлік инфрақұрылымын дамыту",
    },
    {
        id: 21,
        code: "21",
        nameRu: "Увеличение уставного капитала",
        nameKk: "Жарғылық капиталды ұлғайту",
    },
];

export const ProjectTypeList: ProjectType[] = [
    {
        id: 1,
        code: "1",
        nameRu: "Разработка ПСД (Строительство)",
        nameKk: "Жобалық -сметалық құжаттаманы әзірлеу (Құрылыс)",
    },
    {
        id: 2,
        code: "2",
        nameRu: "Разработка ПСД (Реконструкция)",
        nameKk: "Жобалық -сметалық құжаттаманы әзірлеу (Қайта құру)",
    },
    {
        id: 3,
        code: "3",
        nameRu: "Строительство",
        nameKk: "Құрылыс",
    },
    {
        id: 4,
        code: "4",
        nameRu: "Реконструкция",
        nameKk: "Қайта құру",
    },
    {
        id: 5,
        code: "5",
        nameRu: "Уставной капитал",
        nameKk: "Жарғылық капиталы",
    },
    {
        id: 6,
        code: "6",
        nameRu: "Информатизация",
        nameKk: "Ақпараттандыру",
    },
    {
        id: 7,
        code: "7",
        nameRu: "Капитальный ремонт (по дорогам местного значения)",
        nameKk: "Күрделі жөндеу (жергілікті маңызы бар жолдар)",
    },
    {
        id: 8,
        code: "8",
        nameRu: "Средний ремонт (по дорогам местного значения)",
        nameKk: "Орташа жөндеу (жергілікті маңызы бар жолдар)",
    },
];

export interface BipPowerDict extends BipDict {
    objectType: number;
}

export const ProjectPowerList: BipPowerDict[] = [
    {
        id: 1,
        code: "1",
        objectType: 1,
        nameRu: "Количество ученических мест",
        nameKk: "",
    },
    {
        id: 2,
        code: "2",
        objectType: 1,
        nameRu: "Площадь",
        nameKk: "",
    },
    {
        id: 3,
        code: "3",
        objectType: 2,
        nameRu: "Количество ученических мест",
        nameKk: "",
    },
    {
        id: 4,
        code: "3",
        objectType: 2,
        nameRu: "Площадь",
        nameKk: "",
    },
    {
        id: 5,
        code: "5",
        objectType: 24,
        nameRu: "Протяженность",
        nameKk: "",
    },
    {
        id: 6,
        code: "6",
        objectType: 19,
        nameRu: "Протяженность сетей",
        nameKk: "",
    },
    {
        id: 7,
        code: "7",
        objectType: 19,
        nameRu: "Мощность",
        nameKk: "",
    },
    {
        id: 8,
        code: "8",
        objectType: 19,
        nameRu: "Напряжение",
        nameKk: "",
    },
    {
        id: 9,
        code: "9",
        objectType: 19,
        nameRu: "Площадь",
        nameKk: "",
    },
];

export interface BipObjectDict extends BipDict {
    direction: number[];
}

export const ProjectObjectList: BipObjectDict[] = [
    {
        id: 1,
        code: "1",
        nameRu: "Общеобразовательная школа",
        nameKk: "Жалпы білім беретін мектеп",
        direction: [1, 2, 3, 4],
    },
    {
        id: 2,
        code: "2",
        nameRu: "Детский сад",
        nameKk: "Балабақша",
        direction: [1, 2, 3, 4],
    },
    {
        id: 3,
        code: "3",
        nameRu: "Общежития при учебных заведениях",
        nameKk: "Білім беру ұйымдарындағы жатақханалар",
        direction: [1, 2, 3, 4],
    },
    {
        id: 4,
        code: "4",
        nameRu: "Многопрофильная больница",
        nameKk: "Көпбейінді аурухана",
        direction: [1, 2, 3, 4],
    },
    {
        id: 5,
        code: "5",
        nameRu: "Перинатальный центр",
        nameKk: "Перинаталдық орталық",
        direction: [1, 2, 3, 4],
    },
    {
        id: 6,
        code: "6",
        nameRu: "Врачебная амбулатория",
        nameKk: "Дәрігерлік амбулатория",
        direction: [1, 2, 3, 4],
    },
    {
        id: 7,
        code: "7",
        nameRu: "Фельдшерско-акушерский пункт",
        nameKk: "Фельдшерлік-акушерлік станция",
        direction: [1, 2, 3, 4],
    },
    {
        id: 8,
        code: "8",
        nameRu: "Медицинский пункт",
        nameKk: "Медициналық пункт",
        direction: [1, 2, 3, 4],
    },
    {
        id: 9,
        code: "9",
        nameRu: "Медицинский центр",
        nameKk: "Медициналық орталық",
        direction: [1, 2, 3, 4],
    },
    {
        id: 10,
        code: "10",
        nameRu: "Спортивное сооружение",
        nameKk: "Спорттық құрылыс",
        direction: [1, 2, 3, 4],
    },
    {
        id: 11,
        code: "11",
        nameRu: "ИКИ для спортивных сооружений",
        nameKk: "Спорттық құрылысына ИКИ",
        direction: [1, 2, 3, 4],
    },
    {
        id: 12,
        code: "12",
        nameRu: "Сооружение культуры",
        nameKk: "Мәдениет нысанының құрылысы",
        direction: [1, 2, 3, 4],
    },
    {
        id: 13,
        code: "13",
        nameRu: "Система теплоснабжения",
        nameKk: "Жылу жүйесі",
        direction: [1, 2, 3, 4],
    },
    {
        id: 14,
        code: "14",
        nameRu: "Котельная",
        nameKk: "Қазандық",
        direction: [1, 2, 3, 4],
    },
    {
        id: 15,
        code: "15",
        nameRu: "Система водоснабжения",
        nameKk: "Сумен жабдықтау жүйесі",
        direction: [1, 2, 3, 4],
    },
    {
        id: 16,
        code: "16",
        nameRu: "Система водоотведения",
        nameKk: "Cу тарту жүйесі",
        direction: [1, 2, 3, 4],
    },
    {
        id: 17,
        code: "17",
        nameRu: "Многоквартирный жилой дом (МЖД)",
        nameKk: "Көппәтерлі тұрғын үй",
        direction: [1, 2, 3, 4],
    },
    {
        id: 18,
        code: "18",
        nameRu: "Индивидуальное жилищное строительство (ИЖС)",
        nameKk: "Жеке тұрғын үй",
        direction: [1, 2, 3, 4],
    },
    {
        id: 19,
        code: "19",
        nameRu: "ИКИ для МЖД",
        nameKk: "Көппәтерлі тұрғын үй ИКИ",
        direction: [1, 2, 3, 4],
    },
    {
        id: 20,
        code: "20",
        nameRu: "ИКИ для ИЖС",
        nameKk: "Жеке тұрғын үй ИКИ",
        direction: [1, 2, 3, 4],
    },
    {
        id: 21,
        code: "21",
        nameRu: "Объект охраны окружающей среды",
        nameKk: "Қоршаған ортаны қорғау объекті",
        direction: [1, 2, 3, 4],
    },
    {
        id: 22,
        code: "22",
        nameRu: "Объект по сбору, переработке и утилизации ТБО",
        nameKk: "Қатты тұрмыстық қалдықтарды жинау, өңдеу және кәдеге жарату объекті",
        direction: [1, 2, 3, 4],
    },
    {
        id: 23,
        code: "23",
        nameRu: "ИКИ для СЭЗ",
        nameKk: "Арнаулы экономикалық аймақ ИКИ",
        direction: [1, 2, 3, 4],
    },
    {
        id: 24,
        code: "24",
        nameRu: "ИКИ для объектов промышленности",
        nameKk: "Өнеркәсіп объектілерінің ИКИ",
        direction: [1, 2, 3, 4],
    },
    {
        id: 25,
        code: "25",
        nameRu: "Система противодействия ЧС",
        nameKk: "Төтенше жағдайларды жою жүйесі",
        direction: [1, 2, 3, 4],
    },
    {
        id: 26,
        code: "26",
        nameRu: "ИКИ для объектов сельского хозяйства",
        nameKk: "Ауыл шаруашылығы объектілерінің ИКИ",
        direction: [1, 2, 3, 4],
    },
    {
        id: 27,
        code: "27",
        nameRu: "Автомобильная дорога внутри НП",
        nameKk: "Автомобиль жолдары",
        direction: [1, 2, 3, 4],
    },
    {
        id: 28,
        code: "28",
        nameRu: "Благоустройство улицы",
        nameKk: "Көшені көріктендіру",
        direction: [1, 2, 3, 4],
    },
    {
        id: 29,
        code: "29",
        nameRu: "Уставной капитал",
        nameKk: "Жарғылық капиталы",
        direction: [5],
    },
    {
        id: 30,
        code: "30",
        nameRu: "Система электроснабжения",
        nameKk: "Электр жүйелері",
        direction: [1, 2, 3, 4],
    },
    {
        id: 31,
        code: "31",
        nameRu: "Административное здание",
        nameKk: "Әкімшілік ғимараты",
        direction: [1, 2, 3, 4],
    },
    {
        id: 32,
        code: "32",
        nameRu: "ИКИ для прочих объектов МСП в рамках Госпрограмм",
        nameKk: "Мемлекеттік бағдарламалар бойынша басқа ШОБ үшін ИКИ",
        direction: [1, 2, 3, 4],
    },
    {
        id: 33,
        code: "33",
        nameRu: "Пожарное депо",
        nameKk: "Өрт сөндіру депосы",
        direction: [1, 2, 3, 4],
    },
    {
        id: 34,
        code: "34",
        nameRu: "Информационая система",
        nameKk: "Информациялық жүйе",
        direction: [6],
    },
    {
        id: 35,
        code: "35",
        nameRu: "Капитальный ремонт дороги областного значения",
        nameKk: "Облыстық маңызы бар жолдың күрделі жөндеуі",
        direction: [7],
    },
    {
        id: 36,
        code: "36",
        nameRu: "Капитальный ремонт дороги районного значения",
        nameKk: "Аймақтық маңызы бар жолдың күрделі жөндеуі",
        direction: [7],
    },
    {
        id: 37,
        code: "37",
        nameRu: "Средний ремонт дороги областного значения",
        nameKk: "Облыстық маңызы бар жолдың орташа жөндеуі",
        direction: [8],
    },
    {
        id: 38,
        code: "38",
        nameRu: "Средний ремонт дороги районного значения",
        nameKk: "Аймақтық маңызы бар жолдың орташа жөндеуі",
        direction: [8],
    },
];

export interface CapacityIndicatorsFacilities extends BipDict {
    code: string;
    objectCode: number[];
    unit: string;
    data_type: string;
    foreign_data?: boolean;
    source_type: string;
    calc_type: string;
    criteria_type?: string;
}

export const CapacityIndicatorsFacilitiesList: CapacityIndicatorsFacilities[] =
    [
        {
            id: 1,
            code: "1",
            objectCode: [1],
            nameRu: "Текущий дефицит ученических мест в близлежащей школе",
            unit: "детей",
            data_type: "number",
            source_type: "input",
            calc_type: "N",
            criteria_type: "Потребность",
        },
        {
            id: 2,
            code: "2",
            objectCode: [
                1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18,
                19, 20, 21, 22, 23, 24, 25, 27, 28, 29, 30, 31, 32, 33, 35, 36,
                37, 38,
            ],
            nameRu: "Количество созданных рабочих мест",
            unit: "единица",
            data_type: "number",
            source_type: "input",
            calc_type: "",
            criteria_type: "",
        },
        {
            id: 3,
            code: "2.1.",
            objectCode: [
                1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 17, 18, 21, 22,
                23, 24, 25, 29, 33,
            ],
            nameRu: "Количество созданных рабочих мест, постоянные",
            unit: "единица",
            data_type: "number",
            source_type: "input",
            calc_type: "N",
            criteria_type: "Влияние на ЦИ",
        },
        {
            id: 4,
            code: "2.2.",
            objectCode: [
                1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18,
                19, 20, 21, 22, 23, 24, 25, 27, 28, 30, 31, 32, 33, 35, 36, 37,
                38,
            ],
            nameRu: "Количество созданных рабочих мест, временные",
            unit: "единица",
            data_type: "number",
            source_type: "input",
            calc_type: "N",
            criteria_type: "Влияние на ЦИ",
        },
        {
            id: 5,
            code: "3",
            objectCode: [2],
            nameRu: "Дефицит мест в близлежащем детском саду",
            unit: "детей",
            data_type: "number",
            source_type: "input",
            calc_type: "N",
            criteria_type: "Потребность",
        },
        {
            id: 6,
            code: "4",
            objectCode: [3],
            nameRu: "Дефицит мест в ТИПО",
            unit: "студентов",
            data_type: "number",
            source_type: "input",
            calc_type: "N",
            criteria_type: "Потребность",
        },
        {
            id: 7,
            code: "5",
            objectCode: [
                3, 4, 5, 6, 9, 13, 14, 15, 16, 21, 23, 24, 25, 27, 28, 30, 31,
                33, 35, 36, 37, 38,
            ],
            nameRu: "Охват населения в рамках реализации проекта",
            unit: "человек",
            data_type: "number",
            source_type: "input",
            calc_type: "N",
            criteria_type: "Потребность",
        },
        {
            id: 8,
            code: "6",
            objectCode: [
                1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18,
                19, 20, 21, 22, 23, 24, 25, 27, 28, 30, 31, 33, 35, 36, 37, 38,
            ],
            nameRu: "Общественный резонанс: СМИ, забастовки, жалобы в госорганы, отчет акима перед населением",
            unit: "",
            data_type: "boolean",
            source_type: "input",
            calc_type: "B",
            criteria_type: "Потребность",
        },
        {
            id: 9,
            code: "7",
            objectCode: [
                3, 6, 9, 11, 12, 13, 14, 21, 22, 23, 24, 25, 27, 28, 30, 31, 33,
                35, 36, 37, 38,
            ],
            nameRu: "Численность населения НП",
            unit: "человек",
            data_type: "number",
            source_type: "input",
            calc_type: "N",
            criteria_type: "Потребность",
        },
        {
            id: 10,
            code: "8",
            objectCode: [4, 6, 9],
            nameRu: "Стандартизованный коэффициент смертности, текущий",
            unit: "процент",
            data_type: "number",
            source_type: "input",
            calc_type: "N",
            criteria_type: "Потребность",
        },
        {
            id: 11,
            code: "9",
            objectCode: [5],
            nameRu: "Младенческая смертность, количество случаев на 1000 родившихся живыми, текущий",
            unit: "единица",
            data_type: "number",
            source_type: "input",
            calc_type: "N",
            criteria_type: "Потребность",
        },
        {
            id: 12,
            code: "10",
            objectCode: [13, 14, 30],
            nameRu: "Потенциальные потребители: школа, детский сад, больница",
            unit: "единица",
            data_type: "boolean",
            source_type: "input",
            calc_type: "B",
            criteria_type: "Потребность",
        },
        {
            id: 13,
            code: "11",
            objectCode: [17, 18, 19, 20],
            nameRu: "Обеспеченность жильем на одного проживающего, текущая",
            unit: "квадратные метры",
            data_type: "number",
            source_type: "input",
            calc_type: "R",
            criteria_type: "Потребность",
        },
        {
            id: 14,
            code: "12",
            objectCode: [17, 19],
            nameRu: "Строительство многоквартирных жилых домов",
            unit: "единица",
            data_type: "boolean",
            source_type: "input",
            calc_type: "B",
            criteria_type: "Влияние на ЦИ",
        },
        {
            id: 15,
            code: "13",
            objectCode: [17, 19],
            nameRu: "Количество человек в очереди на получение жилья",
            unit: "человек",
            data_type: "number",
            source_type: "input",
            calc_type: "N",
            criteria_type: "Потребность",
        },
        {
            id: 16,
            code: "14",
            objectCode: [18, 20],
            nameRu: "Количество человек в очереди на получение земельного участка",
            unit: "человек",
            data_type: "number",
            source_type: "input",
            calc_type: "N",
            criteria_type: "Потребность",
        },
        {
            id: 17,
            code: "15",
            objectCode: [22],
            nameRu: "Доля переработки и утилизации ТБО к их образованию",
            unit: "процент",
            data_type: "number",
            source_type: "input",
            calc_type: "R",
            criteria_type: "Потребность",
        },
        {
            id: 18,
            code: "16",
            objectCode: [22],
            nameRu: "Объем отходов, поступивших на захоронение в НП в год",
            unit: "тыс. тонн",
            data_type: "number",
            source_type: "input",
            calc_type: "",
            criteria_type: "",
        },
        {
            id: 19,
            code: "17",
            objectCode: [22],
            nameRu: "Запас оставшейся мощности существующих ТБО",
            unit: "тыс. тонн",
            data_type: "number",
            source_type: "input",
            calc_type: "R",
            criteria_type: "Потребность",
        },
        {
            id: 20,
            code: "18",
            objectCode: [24, 26, 27],
            nameRu: "Кол-во хоз-субъектов",
            unit: "единица",
            data_type: "number",
            source_type: "input",
            calc_type: "N",
            criteria_type: "Потребность",
        },
        {
            id: 21,
            code: "19",
            objectCode: [25, 33],
            nameRu: "Уровень обеспеченности инфраструктурой противодействия чрезвычайным ситуациям, текущий",
            unit: "процент",
            data_type: "number",
            source_type: "input",
            calc_type: "R",
            criteria_type: "Потребность",
        },
        {
            id: 22,
            code: "20",
            objectCode: [27],
            nameRu: "Площадь орошаемого участка в рамках реализации проекта",
            unit: "гектар",
            data_type: "number",
            source_type: "input",
            calc_type: "",
            criteria_type: "Потребность",
        },
        {
            id: 23,
            code: "21",
            objectCode: [27, 28, 35, 36, 37, 38],
            nameRu: "Интенсивность автомобилей в сутки (среднегодовая)",
            unit: "единица",
            data_type: "number",
            source_type: "input",
            calc_type: "N",
            criteria_type: "Потребность",
        },
        {
            id: 24,
            code: "22",
            objectCode: [27, 28],
            nameRu: "Численность населения вдоль участка дороги (в пределах 5 км)",
            unit: "человек",
            data_type: "number",
            source_type: "input",
            calc_type: "N",
            criteria_type: "Потребность",
        },
        {
            id: 25,
            code: "23",
            objectCode: [29],
            nameRu: "Софинансирование проекта из собственных средств получателя",
            unit: "млн тенге",
            data_type: "number",
            source_type: "input",
            calc_type: "N",
            criteria_type: "Бюджетные",
        },
        {
            id: 26,
            code: "24",
            objectCode: [29],
            nameRu: "Социальная направленность проекта",
            unit: "",
            data_type: "boolean",
            source_type: "input",
            calc_type: "B",
            criteria_type: "Потребность",
        },
        {
            id: 27,
            code: "25",
            objectCode: [24, 29],
            nameRu: "ENPV",
            unit: "млн тенге",
            data_type: "number",
            source_type: "input",
            calc_type: "N",
            criteria_type: "Бюджетные",
        },
        {
            id: 28,
            code: "26",
            objectCode: [29],
            nameRu: "Соответствие стратегических документам: Наличие индикаторов в ПРО, в Госпрограммах; Наличие Поручений Президента, Премьер Министра, Акима области",
            unit: "",
            data_type: "boolean",
            source_type: "input",
            calc_type: "B",
            criteria_type: "Потребность",
        },
        {
            id: 29,
            code: "27",
            objectCode: [29],
            nameRu: "Доля государства в УК",
            unit: "процент",
            data_type: "number",
            source_type: "input",
            calc_type: "N",
            criteria_type: "Потребность",
        },
        {
            id: 30,
            code: "28",
            objectCode: [30],
            nameRu: "Текущий % оснащения сетями электроснабжения",
            unit: "процент",
            data_type: "number",
            source_type: "input",
            calc_type: "R",
            criteria_type: "Потребность",
        },
        {
            id: 31,
            code: "29",
            objectCode: [31],
            nameRu: "Наличие административного здания",
            unit: "единица",
            data_type: "boolean",
            source_type: "input",
            calc_type: "B",
            criteria_type: "Потребность",
        },
        {
            id: 32,
            code: "30",
            objectCode: [
                1, 2, 3, 4, 5, 6, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20,
                21, 23, 24, 25, 26, 27, 30, 31, 33,
            ],
            nameRu: "Текущий износ объекта",
            unit: "процент",
            data_type: "number",
            source_type: "input",
            calc_type: "N",
            criteria_type: "Потребность",
        },
        {
            id: 33,
            code: "31",
            objectCode: [35, 36, 37, 38],
            nameRu: "Доля автодорог местного значения, находящихся в хорошем и удовлетворительном состоянии, текущая",
            unit: "процент",
            data_type: "number",
            source_type: "input",
            calc_type: "R",
            criteria_type: "Потребность",
        },
        {
            id: 34,
            code: "32",
            objectCode: [27, 28, 35, 36, 37, 38],
            nameRu: "Наличие крупных объектов вдоль учаска дороги: промышленные предприятия, сельхоз предприятия, туристические объекты",
            unit: "единица",
            data_type: "boolean",
            source_type: "input",
            calc_type: "B",
            criteria_type: "Потребность",
        },
        {
            id: 35,
            code: "33",
            objectCode: [35, 36, 37, 38],
            nameRu: "Дата последнего ремонта",
            unit: "дата",
            data_type: "number",
            source_type: "input",
            calc_type: "N",
            criteria_type: "Потребность",
        },
        {
            id: 36,
            code: "34",
            objectCode: [33],
            nameRu: "Наличиe пожарного депо",
            unit: "единица",
            data_type: "boolean",
            source_type: "input",
            calc_type: "B",
            criteria_type: "Потребность",
        },
        {
            id: 37,
            code: "35",
            objectCode: [13],
            nameRu: "Текущий % оснащения сетями теплоснабжения",
            unit: "процент",
            data_type: "number",
            source_type: "input",
            calc_type: "R",
            criteria_type: "Потребность",
        },
        {
            id: 38,
            code: "36",
            objectCode: [23],
            nameRu: "Текущий % оснащения ИКИ для СЭЗ",
            unit: "процент",
            data_type: "number",
            source_type: "input",
            calc_type: "R",
            criteria_type: "Потребность",
        },
        {
            id: 39,
            code: "37",
            objectCode: [23],
            nameRu: "Кол-во потенциальных инвесторов",
            unit: "единица",
            data_type: "number",
            source_type: "input",
            calc_type: "N",
            criteria_type: "Потребность",
        },
        {
            id: 40,
            code: "38",
            objectCode: [24],
            nameRu: "ИКИ для объектов обрабатывающей промышленности",
            unit: "единица",
            data_type: "boolean",
            source_type: "input",
            calc_type: "B",
            criteria_type: "Потребность",
        },
        {
            id: 41,
            code: "39",
            objectCode: [1, 13, 15, 16],
            nameRu: "Наличие аварий за последний год",
            unit: "единица",
            data_type: "boolean",
            source_type: "input",
            calc_type: "B",
            criteria_type: "Потребность",
        },
        {
            id: 42,
            code: "40",
            objectCode: [1],
            nameRu: "Использование мощности объекта",
            unit: "процент",
            data_type: "number",
            source_type: "input",
            calc_type: "N",
            criteria_type: "Потребность",
        },
        {
            id: 43,
            code: "41",
            objectCode: [1, 10, 12, 13, 15, 16, 17, 19, 23, 24, 35, 36, 37, 38],
            nameRu: "% МБ от финансирования 2022",
            unit: "процент",
            data_type: "number",
            source_type: "calc",
            calc_type: "R",
            criteria_type: "Бюджетные",
        },
        {
            id: 44,
            code: "42",
            objectCode: [35, 36, 37, 38],
            nameRu: "Протяженность ремонтируемых участков",
            unit: "километр",
            data_type: "number",
            source_type: "input",
            calc_type: "",
            criteria_type: "",
        },
        {
            id: 45,
            code: "43",
            objectCode: [35, 36, 37, 38],
            nameRu: "Стоимость за 1 км",
            unit: "млн тенге",
            data_type: "number",
            source_type: "calc",
            calc_type: "R",
            criteria_type: "Бюджетные",
        },
        {
            id: 46,
            code: "44",
            objectCode: [1, 10, 12, 13, 15, 16, 17, 19, 23, 24, 35, 36, 37, 38],
            nameRu: "Накопленная инфляция",
            unit: "процент",
            data_type: "number",
            source_type: "calc",
            calc_type: "",
            criteria_type: "",
        },
        {
            id: 47,
            code: "45",
            objectCode: [1, 10, 12, 13, 15, 16, 17, 19, 23, 24, 35, 36, 37, 38],
            nameRu: "Поправочный коэффициент",
            unit: "единица",
            data_type: "number",
            source_type: "calc",
            calc_type: "",
            criteria_type: "",
        },
        {
            id: 48,
            code: "46",
            objectCode: [35, 36, 37, 38],
            nameRu: "Доля автодорог местного значения, находящихся в хорошем и удовлетворительном состоянии**",
            unit: "процент",
            data_type: "number",
            source_type: "calc",
            calc_type: "N",
            criteria_type: "Влияние на ЦИ",
        },
        {
            id: 49,
            code: "47",
            objectCode: [35, 36, 37, 38],
            nameRu: "Проведение ремонтных работ",
            unit: "километр",
            data_type: "number",
            source_type: "calc",
            calc_type: "N",
            criteria_type: "Влияние на ЦИ",
        },
        {
            id: 50,
            code: "48",
            objectCode: [35, 36, 37, 38],
            nameRu: "Численность Населения НП**",
            unit: "человек",
            data_type: "number",
            source_type: "calc",
            calc_type: "N",
            criteria_type: "Потребность",
        },
        {
            id: 51,
            code: "49",
            objectCode: [17],
            nameRu: "Общая площадь квартир",
            unit: "квадратные метры",
            data_type: "number",
            source_type: "input",
            calc_type: "",
            criteria_type: "",
        },
        {
            id: 52,
            code: "50",
            objectCode: [1, 10, 12, 17],
            nameRu: "Стоимость за 1 кв м",
            unit: "тыс. тенге",
            data_type: "number",
            source_type: "calc",
            calc_type: "R",
            criteria_type: "Бюджетные",
        },
        {
            id: 53,
            code: "51",
            objectCode: [17, 19],
            nameRu: "Обеспеченность жильем на одного проживающего**",
            unit: "квадратные метры",
            data_type: "number",
            source_type: "calc",
            calc_type: "N",
            criteria_type: "Влияние на ЦИ",
        },
        {
            id: 54,
            code: "52",
            objectCode: [1, 13, 17],
            nameRu: "Уровень удовлетворенности качеством работы МИО",
            unit: "процент",
            data_type: "number",
            source_type: "input",
            calc_type: "E",
            criteria_type: "Влияние на ЦИ",
        },
        {
            id: 55,
            code: "53",
            objectCode: [17, 19],
            nameRu: "Доступность жилья, отношение среднедушевых доходов населения на среднюю стоимость 1 кв. м. (продажа нового жилья (квартиры в много квартирных домах))",
            unit: "единица",
            data_type: "number",
            source_type: "input",
            calc_type: "E",
            criteria_type: "Влияние на ЦИ",
        },
        {
            id: 56,
            code: "54",
            objectCode: [17, 19],
            nameRu: "Динамика роста населения НП за последние 5 лет",
            unit: "процент",
            data_type: "number",
            source_type: "input",
            calc_type: "N",
            criteria_type: "Потребность",
        },
        {
            id: 57,
            code: "55",
            objectCode: [13],
            nameRu: "Протяженность тепловой сети",
            unit: "километр",
            data_type: "number",
            source_type: "input",
            calc_type: "",
            criteria_type: "",
        },
        {
            id: 58,
            code: "56",
            objectCode: [13],
            nameRu: "Протяженность реконструируемого объекта",
            unit: "километр",
            data_type: "number",
            source_type: "input",
            calc_type: "",
            criteria_type: "",
        },
        {
            id: 59,
            code: "57",
            objectCode: [12, 13],
            nameRu: "Нет прямого ЦИ",
            unit: "единица",
            data_type: "boolean",
            source_type: "input",
            calc_type: "B",
            criteria_type: "Влияние на ЦИ",
        },
        {
            id: 60,
            code: "58",
            objectCode: [23],
            nameRu: "Объем производства на территории СЭЗ «Павлодар», млрд. тенге (специфичный)",
            unit: "единица",
            data_type: "number",
            source_type: "input",
            calc_type: "E",
            criteria_type: "Влияние на ЦИ",
        },
        {
            id: 61,
            code: "59",
            objectCode: [23],
            nameRu: "Количество участников СЭЗ «Павлодар», (специфичный)",
            unit: "единица",
            data_type: "number",
            source_type: "input",
            calc_type: "E",
            criteria_type: "Влияние на ЦИ",
        },
        {
            id: 62,
            code: "60",
            objectCode: [24],
            nameRu: "Рост производительности труда в обрабатывающей промышленности",
            unit: "процент",
            data_type: "number",
            source_type: "input",
            calc_type: "E",
            criteria_type: "Влияние на ЦИ",
        },
        {
            id: 63,
            code: "61",
            objectCode: [24],
            nameRu: "Рост объема экспорта обрабатывающей промышленности в 1,9 раза к уровню 2018 года",
            unit: "процент",
            data_type: "number",
            source_type: "input",
            calc_type: "E",
            criteria_type: "Влияние на ЦИ",
        },
        {
            id: 64,
            code: "62",
            objectCode: [24],
            nameRu: "Рост объема несырьевого экспорта",
            unit: "процент",
            data_type: "number",
            source_type: "input",
            calc_type: "E",
            criteria_type: "Влияние на ЦИ",
        },
        {
            id: 65,
            code: "63",
            objectCode: [1, 10, 12],
            nameRu: "Общая площадь застройки",
            unit: "квадратные метры",
            data_type: "number",
            source_type: "input",
            calc_type: "",
            criteria_type: "",
        },
        {
            id: 66,
            code: "64",
            objectCode: [1],
            nameRu: "Кол-во ученических мест",
            unit: "человек",
            data_type: "number",
            source_type: "input",
            calc_type: "",
            criteria_type: "",
        },
        {
            id: 67,
            code: "65",
            objectCode: [1],
            nameRu: "Средняя стоимость ученического места",
            unit: "млн тенге",
            data_type: "number",
            source_type: "calc",
            calc_type: "R",
            criteria_type: "Бюджетные",
        },
        {
            id: 68,
            code: "66",
            objectCode: [1],
            nameRu: "Уровень удовлетворенности населения качеством дошкольного/среднего образования",
            unit: "процент",
            data_type: "number",
            source_type: "input",
            calc_type: "E",
            criteria_type: "Влияние на ЦИ",
        },
        {
            id: 69,
            code: "67",
            objectCode: [1],
            nameRu: "Прогноз прироста детей в возрасте 6-18 лет через 3 года в НП",
            unit: "процент",
            data_type: "number",
            source_type: "input",
            calc_type: "N",
            criteria_type: "Потребность",
        },
        {
            id: 70,
            code: "68",
            objectCode: [10, 12, 15],
            nameRu: "Уровень обеспеченности сельских населенных пунктов социальными благами и услугами в соответствии с системой региональных стандартов",
            unit: "процент",
            data_type: "number",
            source_type: "input",
            calc_type: "E",
            criteria_type: "Влияние на ЦИ",
        },
        {
            id: 71,
            code: "69",
            objectCode: [12, 15],
            nameRu: "Потребность согласно региональному стандарту",
            unit: "единица",
            data_type: "boolean",
            source_type: "input",
            calc_type: "BR",
            criteria_type: "Потребность",
        },
        {
            id: 72,
            code: "70",
            objectCode: [15],
            nameRu: "Доступ населения к услугам водоснабжения в сельских населенных пунктах (по численности населения)",
            unit: "процент",
            data_type: "number",
            source_type: "input",
            calc_type: "E",
            criteria_type: "Влияние на ЦИ",
        },
        {
            id: 73,
            code: "71",
            objectCode: [15],
            nameRu: "Качество питьевой воды (соответствует/не соответствует СЭС)",
            unit: "единица",
            data_type: "boolean",
            source_type: "input",
            calc_type: "B",
            criteria_type: "Потребность",
        },
        {
            id: 74,
            code: "72",
            objectCode: [15, 16],
            nameRu: "Доступ населения к услугам водоснабжения в городах",
            unit: "процент",
            data_type: "number",
            source_type: "input",
            calc_type: "E",
            criteria_type: "Влияние на ЦИ",
        },
        {
            id: 75,
            code: "73",
            objectCode: [10],
            nameRu: "Норматив по спорт. сооружениям для ГиР",
            unit: "квадратные метры",
            data_type: "number",
            source_type: "calc",
            calc_type: "",
            criteria_type: "",
        },
        {
            id: 76,
            code: "74",
            objectCode: [10],
            nameRu: "Средняя обеспеченность населения спортивной инфраструктурой на 1000 человек, текущее",
            unit: "процент",
            data_type: "number",
            source_type: "input",
            calc_type: "R",
            criteria_type: "Потребность",
        },
        {
            id: 77,
            code: "75",
            objectCode: [10],
            nameRu: "Охват граждан занимающихся физической культурой и спортом",
            unit: "процент",
            data_type: "number",
            source_type: "input",
            calc_type: "E",
            criteria_type: "Влияние на ЦИ",
        },
        {
            id: 78,
            code: "76",
            objectCode: [10],
            nameRu: "Уровень обеспеченности сельских населенных пунктов социальными благами и услугами в соответствии с системой региональных стандартов, текущее %**",
            unit: "процент",
            data_type: "number",
            source_type: "input",
            calc_type: "R",
            criteria_type: "Потребность",
        },
        {
            id: 79,
            code: "77",
            objectCode: [10],
            nameRu: "Средняя обеспеченность населения спортивной инфраструктурой на 1000 человек",
            unit: "процент",
            data_type: "number",
            source_type: "calc",
            calc_type: "N",
            criteria_type: "Влияние на ЦИ",
        },
        {
            id: 80,
            code: "kazPercent",
            objectCode: [
                1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18,
                19, 20, 21, 22, 23, 24, 25, 27, 28, 30, 31, 32, 33, 35, 36, 37,
                38,
            ],
            nameRu: "Казсодержание",
            unit: "процент",
            data_type: "number",
            source_type: "form",
            calc_type: "N",
            criteria_type: "Бюджетные",
        },
        {
            id: 81,
            code: "power.1",
            objectCode: [1],
            nameRu: "Снижение дефицита учен. мест",
            unit: "детей",
            data_type: "number",
            source_type: "form.power",
            calc_type: "N",
            criteria_type: "Влияние на ЦИ",
        },
        {
            id: 82,
            code: "dateStateExamination",
            objectCode: [
                1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18,
                19, 20, 21, 22, 23, 24, 25, 27, 28, 30, 31, 32, 33, 35, 36, 37,
                38,
            ],
            nameRu: "Актуальность госэкспертизы",
            unit: "месяцев",
            data_type: "number",
            source_type: "form",
            calc_type: "R",
            criteria_type: "Бюджетные",
        },
        {
            id: 83,
            code: "EIRR",
            objectCode: [29],
            nameRu: "EIRR",
            unit: "процент",
            data_type: "number",
            source_type: "form",
            calc_type: "N",
            criteria_type: "Бюджетные",
        },
        {
            id: 84,
            code: "84",
            objectCode: [15],
            nameRu: "Уровень удовлетворенности населения экологическим качеством жизни",
            unit: "процент",
            data_type: "number",
            source_type: "calc",
            calc_type: "E",
            criteria_type: "Влияние на ЦИ",
        },
        {
            id: 85,
            code: "85",
            objectCode: [22],
            nameRu: "Наличие полигона для сортировки, хранения или утилизации ТБО",
            unit: "единица",
            data_type: "boolean",
            source_type: "calc",
            calc_type: "B",
            criteria_type: "Потребность",
        },
        {
            id: 86,
            code: "86",
            objectCode: [21],
            nameRu: "Протяженность",
            unit: "метр",
            data_type: "number",
            source_type: "calc",
            calc_type: "N",
        },
        {
            id: 87,
            code: "87",
            objectCode: [21],
            nameRu: "Стоимость за 1 метр",
            unit: "тыс. тенге",
            data_type: "number",
            source_type: "calc",
            calc_type: "R",
            criteria_type: "Бюджетные",
        },
    ];

export type ProjectRealizing = BipDict;

export type ProjectAdmin = BipDict;

export type ProjectLocality = BipDict;

export type ProjectView = BipDict;

export type ProjectConsideration = BipDict;

export type ProjectGovernmentProgram = BipDict;

export class ProjectPeriod {
    get begYear(): number {
        return this._begYear;
    }

    set begYear(value: number) {
        if (value > this._endYear) {
            this._begYear = this._endYear;
        } else {
            this._begYear = value;
        }
    }

    get endYear(): number {
        return this._endYear;
    }

    set endYear(value: number) {
        if (value < this._begYear) {
            this._endYear = this._begYear;
        } else {
            this._endYear = value;
        }
    }

    private _begYear = 2021;
    private _endYear = 2021;

    static fromJSON(d: Record<string, any>): ProjectPeriod {
        return Object.assign(new ProjectPeriod(), d);
    }
}

export class ModellingData {
    get jobsCreated(): number {
        let res = 88;
        let s1 = 0;
        let s2 = 0;
        if (typeof this.jobsCreatedPerm === "string") {
            s1 = parseFloat(this.jobsCreatedPerm);
        }
        if (typeof this.jobsCreatedTemp === "string") {
            s2 = parseFloat(this.jobsCreatedTemp);
        }
        res = s1 + s2;
        return res;
    }

    jobsCreatedPerm = 0;
    jobsCreatedTemp = 0;
    shortageStudentPlaces = 0;
    publicResponse = 0;
    massMedia = 0;
    strikes = 0;
    complaintsState = 0;

    static fromJSON(d: Record<string, any>): ModellingData {
        return Object.assign(new ModellingData(), d);
    }
}

export class BipProjectDataClass implements IBipProjectData {
    public id = 0;
    public code: string;
    public nameRu: string;
    public nameKk: string;

    public header: any;
    public newProject: number;
    public region_budget: string;
    public district_budget: string;

    public region: string;
    /** Населенный пункт по КАТО */
    public localityObject: ILocalityObject | any;
    /** Период реализации */
    public period: ProjectPeriod;
    /** Направление проекта */
    public projectType: string;
    public object: string;
    public nameCode: string;
    public check = false;

    public isDeleted = false;
    public openMore = false;

    /** Объем планируемого казахстанского содержания, в % */
    private _kazPercent: number;
    get kazPercent(): number {
        return this._kazPercent;
    }

    set kazPercent(value: number) {
        if (value >= 0 && value <= 100) {
            this._kazPercent = value;
        } else {
            this._kazPercent = 100;
        }
    }

    get costError(): boolean {
        let s1 = 0;
        if (typeof this.totalCost === "string") {
            s1 = parseFloat(this.totalCost);
        }
        let s2 = 0;
        if (typeof this.costWithout === "string") {
            s2 = parseFloat(this.costWithout);
        }
        return s2 < s1;
    }

    get costErrorSum(): boolean {
        let s1 = 0;
        if (typeof this.totalCost === "string") {
            s1 = parseFloat(this.totalCost);
        }
        let s2 = 0;
        if (typeof this.considerationGA === "string") {
            s2 = parseFloat(this.considerationGA);
        }
        return s2 < s1;
    }

    /** Дата государственной экспертизы */
    private _dateStateExamination: Date | null;
    set dateStateExamination(value: Date | null) {
        if (value !== null && value > new Date()) {
            this._dateStateExamination = new Date();
        } else {
            this._dateStateExamination = value;
        }
    }

    get dateStateExamination() {
        return this._dateStateExamination;
    }

    /** Экономическая внутренняя норма доходности (EIRR) */
    public isDirectCash: boolean;
    public directCash: number;

    public governmentProgram: IGovProgram[] | any = [];
    /** Направлено на рассмотрение в ЦГО */
    public isConsiderationCentralGovernment: boolean;
    /** Сумма запроса ЦТ из РБ, тыс. тенге */
    public considerationGA: number;
    public numStateExpertise: string;
    public totalCost: number;
    public costWithout: number;
    public power?: number;
    public modellingData: ModellingData;

    /** Финансирование до начала планового периода */
    public oldFinace: RowOldFinanceData[] = [];

    /** Финансирование в периоде планового периода (инициатор) */
    public newFinace: RowNewFinanceData[] = [];

    /** Финансирование в периоде планового периода (получатель инвестиций) */
    public newFinaceRegional: RowNewFinanceData[] = [];

    public criteriaIndicators = new CriteriaIndicators();
    public powerIndicators = new CriteriaIndicators();
    public calcResult: any;
    public files: any[] = [];
    public variant: string = "";
    public variant_recipient: string = "";
    public versions: any[] = [];
    public addDatas: any = {};

    public constructor() {
        this.id = 0;
        this.code = "BIP-00000";
        this.nameKk = "Проектная работа";
        this.nameRu = "Проектная работа";
        this.newProject = 1;
        this.header = {} as HeaderData;
        this.region = "";
        this.localityObject = {} as ILocalityObject;
        this.region_budget = "";
        this.district_budget = "";
        this.object = "";
        this.projectType = "";
        this.period = new ProjectPeriod();
        this.modellingData = new ModellingData();
        this.criteriaIndicators = new CriteriaIndicators();
        this.oldFinace = [];
        this.newFinace = [];
        this.newFinaceRegional = [];
        this.files = [];
        this.isConsiderationCentralGovernment = false;
        this.isDirectCash = false;
        this._dateStateExamination = null;
        this.governmentProgram = [];
        this.numStateExpertise = "";
        this.considerationGA = 0;
        this.costWithout = 0;
        this.directCash = 0;
        this._kazPercent = 0;
        this.totalCost = 0;
        this.calcResult = {};
        this.variant = "";
        this.variant_recipient = "";
        this.nameCode = "";
        this.isDeleted = false;
    }

    static assign(d: BipProjectDataClass) {
        const data: BipProjectDataClass = Object.assign(new BipProjectDataClass(), d);
        data.period = ProjectPeriod.fromJSON(d.period);
        data.modellingData = ModellingData.fromJSON(d.modellingData);
        data.criteriaIndicators = CriteriaIndicators.fromJSON(d.criteriaIndicators);
        data.calcResult = Object.assign(Object(), d.calcResult);
        data.powerIndicators = CriteriaIndicators.fromJSON(d.powerIndicators);
        data.newFinace = RowNewFinanceData.fromJSON(d.period, d.newFinace);
        data.newFinaceRegional = RowNewFinanceData.fromJSON(d.period, d.newFinaceRegional);
        data.oldFinace = RowOldFinanceData.fromJSON(d);

        return data;
    }

    static copy(d: BipProjectDataClass) {
        const data = d;
        return data;
    }

    static transit(variant: any, d: BipProjectDataClass): BipProjectDataClass {
        const data: BipProjectDataClass = Object.assign(
            new BipProjectDataClass(),
            d,
        );
        data.id = 0;
        data.newProject = 0;
        data.header = Object.assign({}, d.header);
        data.header.year = variant.year;
        data.header.dataType = variant.data_type.toString();
        data.period = ProjectPeriod.fromJSON(d.period);
        data.modellingData = ModellingData.fromJSON(d.modellingData);
        data.criteriaIndicators = CriteriaIndicators.fromJSON(
            d.criteriaIndicators,
        );
        data.powerIndicators = CriteriaIndicators.fromJSON(d.powerIndicators);
        data.oldFinace = RowOldFinanceData.fromJSON(data);
        data.variant = "";
        data.variant_recipient = "";
        // data.files = [];

        return data;
    }

    static fromJSON(d: Record<string, any> | BipProjectDataClass): BipProjectDataClass {
        const data: BipProjectDataClass = Object.assign(new BipProjectDataClass(), d);
        try {
            data.period = ProjectPeriod.fromJSON(d.period);
            data.modellingData = ModellingData.fromJSON(d.modellingData);
            data.criteriaIndicators = CriteriaIndicators.fromJSON(d.criteriaIndicators);
            data.calcResult = Object.assign(Object(), d.calcResult);
            data.powerIndicators = CriteriaIndicators.fromJSON(d.powerIndicators);
            data.newFinace = RowNewFinanceData.fromJSON(d.period, d.newFinace);
            if (parseInt(d.header.finalAbp) === 0 && d?.newFinaceRegional) {
                data.newFinaceRegional = RowNewFinanceData.fromJSON(d.period, d?.newFinaceRegional);
            }
            data.oldFinace = RowOldFinanceData.fromJSON(d);
        } catch (e) {
            console.log("err => ", data.id, "(e)", e.toString());
        }
        return data;
    }

    static isEqual(object1: any, object2: any): boolean {
        const props1 = Object.keys(object1).filter((field: string) =>
            !['edited', 'editing', 'finCost', 'name_kk', 'name_en', 'name_ru',
                'nameCode', 'addDatas', 'versions', 'parent_id'].includes(field));
        const props2 = Object.keys(object2).filter((field: string) =>
            !['edited', 'editing', 'finCost', 'name_kk', 'name_en', 'name_ru',
                'nameCode', 'addDatas', 'versions', 'parent_id'].includes(field));

        if (props1.length != props2.length) {
            console.log("notEqual1", props1, props2);
            return false;
        }

        for (const prop of props2) {
            try {

                if (['header', 'regional', 'gu', 'period', 'modellingData',
                    'criteriaIndicators', 'calcResult', 'powerIndicators',
                    'localityObject'].includes(prop)) {

                    if (!BipProjectDataClass.isEqual(object1[prop], object2[prop])) {
                        console.log("notEqual2", prop, object1[prop], object2[prop]);
                        return false;
                    }
                }

                if (['oldFinace', 'newFinace', 'newFinaceRegional', 'files', 'governmentProgram']
                    .includes(prop)) {

                    if (object1[prop].length != object2[prop].length) {
                        console.log("notEqual3", prop, object1[prop], object2[prop]);
                        return false;
                    }
                    for (let i = 0; i < object2[prop].length; i++) {

                        if (!BipProjectDataClass.isEqual(object1[prop][i], object2[prop][i])) {
                            console.log("notEqual4", prop, i, object1[prop][i], object2[prop][i]);
                            return false;
                        }
                    }
                }

                if (!(object1[prop] === null && object2[prop] === null)
                    && object1[prop] && object2[prop]) {

                    if (['string', 'number', 'boolean'].includes(typeof object1[prop])) {

                        if (!(object1[prop] === null || object1[prop].isEmpty || object1[prop])
                            && !(object2[prop] === null || object2[prop].isEmpty ||  object2[prop])
                            && object1[prop] !== object2[prop]) {
                            console.log("notEqual5", prop, object1[prop], object2[prop]);
                            return false;
                        }
                    } else {
                        for (let i = 0; i < object2[prop].length; i++) {

                            if (!BipProjectDataClass.isEqual(object1[prop][i], object2[prop][i])) {
                                console.log("notEqual6", prop, i, object1[prop][i], object2[prop][i]);
                                return false;
                            }

                            for (const [key, value] of Object.entries(object2[prop][i])) {

                                if (key.includes('date')
                                    && object1[prop][i][key].toString() === "Invalid Date"
                                    && object2[prop][i][key].toString() === "Invalid Date") {

                                    continue;
                                } else {

                                    if (!['edited', 'editing', 'finCost', 'name_kk', 'name_en', 'name_ru',
                                        'nameCode', 'addDatas', 'versions', 'parent_id'].includes(key)
                                        && object1[prop][i][key] != object2[prop][i][key]) {
                                        console.log("notEqual7", key, object1[prop][i][key], object2[prop][i][key]);
                                        return false;
                                    }
                                }
                            }
                        }
                    }
                }

            } catch (error) {
                console.log("error isEqual8", prop, object1[prop], object2[prop]);
            }
        }
        return true;
    }
}

export class RowOldFinanceData {
    openMore = false;
    year = 2021;
    gr: number | null = null;
    pgr: number | null = null;
    abp: number | null = null;
    prg: number | null = null;
    ppr: number | null = null;
    spf: number | null = null;
    nameRu = "";
    plan = 0;
    podrad = "";
    date: Date | null = null;
    totalCoast = 0;
    smp = 0;
    control = 0;
    nadzor = 0;

    get isSumError(): boolean {
        let s1 = 0;
        let s2 = 0;
        let s3 = 0;
        let all = 0;
        if (typeof this.smp === "string") {
            s1 = parseFloat(this.smp);
        }
        if (typeof this.control === "string") {
            s2 = parseFloat(this.control);
        }
        if (typeof this.nadzor === "string") {
            s3 = parseFloat(this.nadzor);
        }
        if (typeof this.totalCoast === "string") {
            all = parseFloat(this.totalCoast);
        }
        const partSum = s1 + s2 + s3;
        return !(all < partSum);
    }

    constructor(
        gr: number | null,
        pgr: number | null,
        abp: number | null,
        prg: number | null,
        ppr: number | null,
        spf: number | null,
        year: number,
        plan: number | any,
    ) {
        this.gr = gr;
        this.pgr = pgr;
        this.abp = abp;
        this.prg = prg;
        this.ppr = ppr;
        this.spf = spf;
        this.year = year;
        this.plan = plan;
    }

    static fromJSON(d: Record<string, any>): RowOldFinanceData[] {
        const oldFinace: RowOldFinanceData[] = [];
        if (Array.isArray(d.oldFinace) && d.oldFinace.length > 0) {
            for (const row of d.oldFinace) {
                if ("prg" in row && "ppr" in row && "spf" in row) {
                    const old: RowOldFinanceData = Object.assign(
                        new RowOldFinanceData(
                            row?.gr,
                            row?.pgr,
                            row?.abp,
                            row?.prg,
                            row.ppr,
                            row.spf,
                            row.year,
                            row.plan,
                        ),
                        row,
                    );
                    old.date = new Date(Date.parse(row.date));
                    oldFinace.push(old);
                }
            }
        } else {
            if (parseInt(d.header.finalAbp) === 1) {
                for (const obj of d.newFinace) {
                    for (const [key, value] of Object.entries(obj)) {
                        if (parseInt(key) < parseInt(d.header?.year)) {
                            if (value) {
                                if (
                                    "prg" in obj &&
                                    "ppr" in obj &&
                                    "spf" in obj
                                ) {
                                    const row = new RowOldFinanceData(
                                        obj?.gr,
                                        obj?.pgr,
                                        obj?.abp,
                                        obj?.prg,
                                        obj.ppr,
                                        obj.spf,
                                        parseInt(key),
                                        value,
                                    );
                                    oldFinace.push(row);
                                }
                            }
                        }
                    }
                }
            } else {
                if (d.hasOwnProperty("newFinaceRegional")) {
                    for (const obj of d.newFinaceRegional) {
                        for (const [key, value] of Object.entries(obj)) {
                            if (parseInt(key) < parseInt(d.header?.year)) {
                                if (value) {
                                    if (
                                        "prg" in obj &&
                                        "ppr" in obj &&
                                        "spf" in obj
                                    ) {
                                        const row = new RowOldFinanceData(
                                            obj?.gr,
                                            obj?.pgr,
                                            obj?.abp,
                                            obj?.prg,
                                            obj.ppr,
                                            obj.spf,
                                            parseInt(key),
                                            value,
                                        );
                                        oldFinace.push(row);
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
        return oldFinace;
    }
}

export class CriteriaIndicators {
    [index: string]: number | boolean;

    static fromJSON(d: Record<string, any>): CriteriaIndicators {
        return Object.assign(new CriteriaIndicators(), d);
    }
}

export class RowNewFinanceData {
    gr: number | null = null;
    pgr: number | null = null;
    prg: number | null = null;
    ppr: number | null = null;
    spf: number | null = null;

    [index: number]: number;

    openMore: boolean = false;

    constructor(period: ProjectPeriod) {
        for (let i = period.begYear; i <= period.endYear; i++) {
            this[i] = 0;
        }
    }

    static fromJSON(
        period: ProjectPeriod,
        d: RowNewFinanceData[],
    ): RowNewFinanceData[] {
        const arr = [];
        for (const item of d) {
            arr.push(Object.assign(new RowNewFinanceData(period), item));
        }
        return arr;
    }
}

// common methods
export function makeToast(vue: any, variant: any, title: string, tostbody: any) {
    vue.$bvToast.toast(tostbody, {
        title: title,
        variant: variant,
        toaster: "b-toaster-top-center",
        autoHideDelay: 5000,
        appendToast: true
    });
} // сообщение

export function years() {
    const arrYears: any[] = [];
    const curYear = new Date().getFullYear();
    for (let i = curYear + 1; i >= 2022; i--) {
        const item = { year: i, name: `${i}-${i + 2}` };
        arrYears.push(item);
    }
    return arrYears;
}

export function getRowKey(row: any, keys: any[]) {
    let key = "";
    for (const k of keys) {
        key = key + (row[k] === null ? "_" : padLeadingZeros(row[k], 3)) + ".";
    }
    return key;
} // преобразует значения выбранных полей в код

export function doRowKey(keys: any[]) {
    let key = "";
    for (const k of keys) {
        key = key + (k === undefined || k === null ? "_." : padLeadingZeros(k, 3) + ".");
    }
    return key;
} // преобразует значения keys в код

export function padLeadingZeros(num: any, size: number) {
    let s = String(num);
    while (s.length < size) {
        s = "0" + s;
    }
    return s;
} // добавляет 0-ли перед num до size-значного размера

export function sortByField(field: string, sort: string = "asc") {
    if (sort === "asc") {
        return (a: any, b: any) => (a[field] > b[field] ? 1 : -1);
    } else {
        return (a: any, b: any) => (a[field] < b[field] ? 1 : -1);
    }
} // сортирует по наименованию поля

export function findItem(code: number | string | null, field: string, list: any[]) {

    const nullRes = {code: null, name_ru: "", name_kk: "", name_en: "", value: { child: [] }};
    if (code === null) {
        return nullRes;
    }

    for (const item of list) {
        if (item.hasOwnProperty(field) && item[field] !== null) {
            switch (typeof code) {
                case "number":
                    if (parseInt(item[field]) === code) {
                        return item;
                    }
                case "string":
                    if (item[field].toString() === code) {
                        return item;
                    }
                default: break;
            }
        }
    }
    return nullRes;
} // находит элемент из заданного справочника по заданному полю

export function findItemGeneric<T extends Record<string, any>>(
    code: number | string,
    field: string,
    list: T[]
): T | IFallbackFindItemResponse {
    for (const item of list) {
        if (item.hasOwnProperty(field) && item[field] !== null) {
            switch (typeof code) {
                case "number":
                    if (parseInt(item[field]) === code) {
                        return item;
                    }
                    break;
                case "string":
                    if (item[field].toString() === code) {
                        return item;
                    }
                    break;
                default: break;
            }
        }
    }
    return { code: null, name_ru: "", name_kz: "", name_en: "" };
}

export function getNumber(value: unknown | undefined | null | boolean | string | number, digit: number = 1): number {

    if (["unknown", "undefined"].includes(typeof value) || value === null) {
        return 0;
    }
    if (typeof value === "string") {
        return parseFloat(parseFloat(value).toFixed(digit));
    }
    if (typeof value === "boolean") {
        return value ? 1 : 0;
    }
    if (typeof value === "number") {
        return parseFloat(value.toFixed(digit));
    }
    return 0;
} // возращает число

export function checkAccess(
    bip: BipProjectDataClass,
    operation: string,
    oper_recipient: string,
    selRegion: string
) {
    const bipcode = "0";
    if (bip && bip.header) {
        const regional =
            bip.header.hasOwnProperty("regional") && bip.header.regional;
        if (bip.addDatas.status && bip.addDatas.status.steps) {
            const operation_codes: any[] = [];
            for (const step of bip.addDatas.status.steps) {
                if (step.operation_code !== null) {
                    step.operation_code.forEach((oper: string) => {
                        operation_codes.push(oper);
                    });
                }
            }
            if (bip.code === bipcode) {
                console.log(bip, operation_codes, operation, operation_codes.includes(operation), selRegion);
            }
            // condition #1
            if (
                operation_codes.length === 0 ||
                (!operation_codes.includes(operation) &&
                    !operation_codes.includes(oper_recipient))
            ) {
                if (bip.code === bipcode) {
                    console.log("cond1", bip);
                }
                return false;
            }
            // condition #2
            if (
                operation_codes.includes(operation) &&
                !operation_codes.includes(oper_recipient) &&
                bip.region_budget === selRegion
            ) {
                if (bip.code === bipcode) {
                    console.log("cond2", bip);
                }
                return true;
            }
            // condition #3
            if (
                !operation_codes.includes(operation) &&
                operation_codes.includes(oper_recipient) &&
                bip.district_budget === selRegion
            ) {
                if (bip.code === bipcode) {
                    console.log("cond3", bip);
                }
                return true;
            }
            // condition #4
            if (
                operation_codes.includes(operation) &&
                operation_codes.includes(oper_recipient)
            ) {
                if (regional && bip.district_budget === selRegion) {
                    if (bip.code === bipcode) {
                        console.log("cond4", bip);
                    }
                    return true;
                }
                if (!regional && bip.region_budget === selRegion) {
                    if (bip.code === bipcode) {
                        console.log("cond5", bip);
                    }
                    return true;
                }
            }
        }
    }
    if (bip.code === bipcode) {
        console.log("cond6", bip);
    }
    return false;
}

export async function findBipVariantNull(vue: any, bip: BipProjectDataClass, variant: string, variant_rec: string) {
    let result = -1;
    const datas = {
        id: bip.id,
        code: bip.code,
        variant: variant,
        variant_recipient: variant_rec
    };
    try {
        await postData("/api-py/find-bip-variant-null", datas)
            .then((data) => {
                result = data;
                if (data > 0) {
                    let sms = 'Редактирование ' + bip.code + ' невозможно. ';
                    if (`name_${i18nService.locale}` === 'kk') {
                        sms = bip.code + ' жобаны түзету мүмкін емес. ';
                    }
                    if (`name_${i18nService.locale}` === 'en') {
                        sms = 'Editing ' + bip.code + ' is not possible. ';
                    }
                    makeToast(vue, "warning", getSmsTitle(vue, 'attention'),
                        sms + getSmsTitle(vue, 'recipient_not_switched_new_version'));
                    vue.saving = false;
                }
            });
    } catch (error) {
        console.log('/find-bip-variant-null', error.toString());
    } finally {
        return result;
    }
}

export async function  findBipVariantRecNull(vue: any, bip: BipProjectDataClass, variant: string, variant_rec: string) {
    let result = -1;
    const datas = {
        id: bip.id,
        code: bip.code,
        variant: variant,
        variant_recipient: variant_rec
    };
    try {
        await postData("/api-py/find-bip-variant-rec-null", datas)
            .then((data) => {
                result = data;
                if (data > 0) {
                    let sms = 'Редактирование ' + bip.code + ' невозможно. ';
                    if (`name_${i18nService.locale}` === 'kk') {
                        sms = bip.code + ' жобаны түзету мүмкін емес. ';
                    }
                    if (`name_${i18nService.locale}` === 'en') {
                        sms = 'Editing ' + bip.code + ' is not possible. ';
                    }
                    makeToast(vue, "warning", getSmsTitle(vue, 'attention'),
                        sms + getSmsTitle(vue, 'initiator_not_switched_new_version'));
                    vue.saving = false;
                }
            });
    } catch (error) {
        console.log('/find-bip-variant-rec-null', error.toString());
    } finally {
        return result;
    }
}

export function checkGu(userGus: any[], form: BipProjectDataClass) {
    if (userGus.length > 0 && form.header.gu !== null) {
        return userGus.includes(form.header.gu.code);
    }
    return false;
}

export function checkGuReg(userGus: any[], form: BipProjectDataClass) {
    if (userGus.length > 0 && form.header.regional.gu !== null) {
        return (
            form?.header.regional &&
            userGus.includes(form.header.regional.gu.code)
        );
    }
    return false;
}

export function setNameLang(vue: any, obj: any, codeName?: any | null): any {
    let txt = "Empty name";
    if (obj) {
        txt = obj["name_" + vue.$i18n.locale];
        if (txt === undefined) {
            txt = obj.name_ru;
        }
        if (
            codeName !== undefined &&
            codeName !== null &&
            obj[codeName] !== undefined
        ) {
            txt = obj[codeName] + " - " + txt;
        }
    }
    const el = Object.assign({}, obj);
    el.name = txt;
    return el;
}

export function setVariants(vue: any, bip: BipProjectDataClass) {
    if (vue.versionList && bip.header) {
        // определяем вариант инициатора
        const versions = vue.versionList.filter(
            (row: any) => row.attribute &&
                row.year === bip.header.year &&
                row.region_code === bip.region_budget &&
                row.data_type === bip.header.dataType
        );
        vue.$set(bip, "variant", versions.length > 0 ? versions[0].variant_uuid : "");

        // определяем региональный вариант
        if (bip.header.finalAbp === 0 && bip.header.regional) {
            const reg_versions = vue.versionList.filter(
                (row: any) => row.attribute &&
                    row.year === bip.header.year &&
                    row.region_code === bip.district_budget &&
                    row.data_type === bip.header.regional.dataType,
            );
            vue.$set(bip, "variant_recipient", reg_versions.length > 0 ? reg_versions[0].variant_uuid : "");
        }
    }
}

export function filterVersion(vue: any): any[] {
    let result: any[] = [];
    if (vue.planPeriod && vue.selRegionBudget && vue.selDataType && vue.versionList) {
        result = vue.versionList.filter((row: any) =>
            row.year === vue.planPeriod.year
            && row.region_code === vue.selRegionBudget.code
            && row.data_type === parseInt(vue.selDataType.code))
            .sort(sortByField("date_start", "desc"));
    }

    return result;
} // фильтр версий по выбранным году, региону и типу данных

export function versionByRegion(vue: any) {
    if (vue.planPeriod === null && vue.selDataType === null
        && vue.selRegionBudget && vue.selRegionBudget !== null
        && vue.dataTypeList.length > 0 && vue.versionList.length > 0) {

        const list = vue.versionList
            .filter((row: any) => row.region_code === vue.selRegionBudget.code)
            .sort(sortByField("date_start", "desc"));

        let selVariant: any;
        for (const row of list) {
            if (row.attribute) {
                selVariant = row;
                break;
            }
        }

        if (!selVariant) {
            for (const row of list) {
                if (row.status) {
                    selVariant = row;
                    break;
                }
            }
        }

        vue.planPeriod = findItem(selVariant.year, 'year', years());
        vue.selDataType = findItem(selVariant.data_type, "code", vue.dataTypeList);
        // vue.selVersion = selVariant;
    }

    setTimeout(() => {
        if (vue.selVersion === null) {
            versionByRegion(vue);
        }
    }, 2000);

} // update version by region

export function defineBip(vue: any, bip: BipProjectDataClass, mode: string) {
    const bipCode = '0';
    if (bip === undefined) {
        return;
    }
    try {
        // в addDatas - вставляем ключевые отображаемые в интерфейсе данные
        if (!bip.hasOwnProperty('addDatas')) {
            vue.$set(bip, "addDatas", {});
        }
        // наименованиЯ в БИПе по locale
        itemNameLocale(bip);
        itemNameLocale(bip.localityObject);
        if (Array.isArray(bip.governmentProgram)) {
            nameLocale(bip.governmentProgram);
        } else {
            if ((bip.governmentProgram.length > 0) && vue.gpList) {
                const gp = findItem(bip.governmentProgram, 'code', vue.gpList);
                vue.$set(bip, 'governmentProgram', gp);
            }
        }

        vue.$set(bip, "openMore", false);
        vue.$set(bip, "check", false);
        vue.$set(bip.header, "editing", false);

        vue.$set(bip["addDatas"], "abp", { abp: null, text: "" });
        vue.$set(bip["addDatas"], "gu", {});
        vue.$set(bip["addDatas"], "dataType", vue.selVersion.data_type.toString());

        const agr = vue.agrMap.get(bip.code);
        if (agr) {
            itemNameLocale(agr);
        }
        if (!['save'].includes(mode)) {
            vue.$set(bip["addDatas"], "status", agr);
        }

        if (bip.id === 8876) {
            console.log('0', bip)
        }
        if (mode === "uebp") {
            vue.$set(bip.addDatas, "edited", false);
            vue.$set(bip.addDatas, "link", "/#/bip/bip-form?bip_id=" + bip.id);
        }
        if (bip.id === 8876) {
            console.log('1', bip)
        }
        // дополнительные поля для режима Оценки и Развития
        if (["grade", "uebp"].includes(mode)) {
            vue.$set(bip, "_showDetails", false);

            // region/locality
            try {
                const reg = findItem(bip?.region, "code", vue.regionList);
                if (bip.localityObject) {
                    itemNameLocale(bip.localityObject);
                }
                vue.$set(bip.addDatas, "reg_loc", (reg ? reg.name : '') + "/ "
                    + (bip.localityObject && bip.localityObject.name ? bip.localityObject.name : "")
                );
            } catch (error) {
                console.log("err(reg_loc) =>", bip.id, bip?.region,  bip?.localityObject, error);
            }
            // project type/object
            try {
                const project = findItem(bip?.projectType, "code", vue.directionList);
                const object = findItem(bip?.object, "code", vue.objectList);
                vue.$set(bip.addDatas, "type_obj", project.name + "/ " + object.name);
            } catch (error) {
                console.log("err(type_obj) =>", bip?.projectType, bip?.object, error);
            }
            // governmentProgram
            try {
                let str = "";
                if (typeof (bip?.governmentProgram) === 'string') {
                    str = findItem(bip?.governmentProgram, 'code', vue.gpList).name;
                } else {
                    for (const gp of bip?.governmentProgram) {
                        if (str.length === 0) {
                            str = gp.name;
                        } else {
                            str = str + ", " + gp.name;
                        }
                    }
                }
                vue.$set(bip.addDatas, "program", str);
            } catch (error) {
                console.log("err(gos_program) =>", bip?.id, error);
            }
            // newProject
            try {
                vue.$set(bip.addDatas, "exp_type", "");
                if (bip.hasOwnProperty("newProject")) {
                    // @ts-ignore
                    const np = findItem(bip["newProject"] as number | string, "value", vue.expTypes);
                    if (!np.hasOwnProperty("code")) {
                        vue.$set(bip.addDatas, "exp_type", np.name);
                    }
                }
            } catch (error) {
                console.log("err(newProject) =>", bip.id, error);
            }
            // createDetail
            try {
                createDetail(vue, bip);
            } catch (error) {
                console.log("err(createDetail) =>", bip.id, error.toString());
            }
        }
        if (bip.id === 8876) {
            console.log('2', bip)
        }
        vue.$set(bip["addDatas"], "finace", []);
        // инициатор
        if (bip.id === 8876) {
            console.log('3', bip)
        }
        if (bip.variant === vue.selVersion.variant_uuid) {
            vue.$set(bip["addDatas"], "region", bip.region_budget);
            const abp = vue.ebkMap.get(bip?.header?.abp + ".");

            if (bip?.header?.abp && abp) {
                vue.$set(bip["addDatas"], "abp", abp);
                vue.$set(bip["addDatas"], "gu", bip?.header?.gu.code);

                const finace: any[] = [];
                bip.newFinace.sort(sortByField("ppr"));
                bip.newFinace.forEach((fin: RowNewFinanceData) => {
                    const prg = findItem(fin['prg'], 'prg', abp.value.child);
                    if (prg) {
                        vue.$set(fin, "gr", prg.value.gr);
                        vue.$set(fin, "pgr", prg.value.pgr);
                        vue.$set(fin, "abp", prg.value.abp);
                    }

                    const newCost: any = {
                        gr: fin.gr,
                        pgr: fin.pgr,
                        abp: bip?.header?.abp,
                        prg: fin.prg,
                        ppr: fin.ppr,
                        spf: fin.spf
                    };

                    for (let i = 0; i < 3; i++) {
                        vue.$set(newCost, "fact" + (i + 1), getNumber(fin[vue.selVersion.year + i]));

                        if (mode === "uebp" && (bip.code && bip.header.abp && fin.prg)) {
                            const plan = vue.costsMap.get(
                                doRowKey([bip.code, bip?.header.abp, fin.prg, fin.ppr, fin.spf,
                                    vue.selVersion.year + i, bip.variant]));
                            vue.$set(newCost, "plan" + (i + 1), plan ? plan.value : 0);
                        }
                    }
                    if (["grade", "uebp"].includes(mode)) {
                        vue.$set(newCost, "ppr_cost", pprFinans(fin, bip?.period));
                    }
                    finace.push(newCost);
                });
                vue.$set(bip["addDatas"], "finace", finace);
            }
        }

        if (bip.id === 8876) {
            console.log('4', bip, bip.variant_recipient === vue.selVersion.variant_uuid, bip.header.finalAbp === 0)
        }
        // получатель
        if (bip.variant_recipient === vue.selVersion.variant_uuid) {
            vue.$set(bip["addDatas"], "region", bip.district_budget);
            const abp = vue.ebkMap.get(bip.header?.regional.abp + ".");

            if (bip.header?.regional && bip.header?.regional.abp && abp) {
                vue.$set(bip["addDatas"], "abp", abp);
                vue.$set(bip["addDatas"], "gu", bip?.header?.regional.gu.code);

                if (bip.id === 8876) {
                    console.log('5', bip)
                }
                const finace: any[] = [];
                bip?.newFinaceRegional.sort(sortByField("ppr"));
                bip?.newFinaceRegional.forEach((fin: RowNewFinanceData) => {
                    const prg = findItem(fin['prg'], 'prg', abp.value.child);
                    if (prg) {
                        vue.$set(fin, "gr", prg.value.gr);
                        vue.$set(fin, "pgr", prg.value.pgr);
                        vue.$set(fin, "abp", prg.value.abp);
                    }

                    const newCost = {
                        gr: fin.gr,
                        pgr: fin.pgr,
                        abp: bip?.header?.regional.abp,
                        prg: fin.prg,
                        ppr: fin.ppr,
                        spf: fin.spf
                    };
                    for (let i = 0; i < 3; i++) {
                        vue.$set(newCost, "fact" + (i + 1), getNumber(fin[vue.selVersion.year + i]));

                        if (mode === "uebp"
                            && (bip.code && bip.header.regional.abp && fin.prg)) {
                            const plan = vue.costsMap.get(
                                doRowKey([bip.code, bip.header.regional.abp, fin.prg, fin.ppr, fin.spf,
                                    vue.selVersion.year + i, bip.variant_recipient]),
                            );
                            vue.$set(newCost, "plan" + (i + 1), plan ? plan.value : 0);
                        }
                    }
                    if (["grade", "uebp"].includes(mode)) {
                        vue.$set(newCost, "ppr_cost", pprFinans(fin, bip?.period));
                    }
                    finace.push(newCost);
                });
                vue.$set(bip["addDatas"], "finace", finace);
            }
        }

    } catch (error) {
        console.log("defineBip =>", bip, error);
    }
}

export function createDetail(vue: any, bip: BipProjectDataClass) {

    const item: any = bip;

    const details = { table: [{}] };
    const row3Fields = [];
    const mapBudget = new Map(); // Бюджетные
    const mapImpact = new Map(); // Влияния
    const mapNeeds = new Map(); // Потребность
    let bipBudget = 0;
    let bipImpact = 0;
    let bipNeeds = 0;

    // first row of details - weights
    let weight = 0;
    const row1 = { name: getTFieldTitle(vue, 'ind_weight') };
    if (bip.hasOwnProperty("criteriaIndicators")) {
        for (const [key, value] of Object.entries(bip?.criteriaIndicators)) {
            if (key.includes("weight") && !isNaN(<number>value) && value.toString().length > 0) {

                const fieldName = key.substr(0, key.length - 6); // наименование поля
                const field = vue.detailFields.filter(function (f: any) {
                    if (f.key === fieldName) {
                        return f;
                    }
                }); // находим наим.поля из заданного перечня

                if (field.length > 0
                    && ["1", "2", "3", "4"].includes(field[0]?.criteria_type)) {
                    const val = getNumber(value, 2); // значение поля
                    vue.$set(row1, fieldName, val); // запись в первую строку
                    weight += val; // общая сумма баллов по весу

                    switch (field[0].criteria_type) {
                        case "1":
                            mapBudget.set(field[0], { weight: val });
                            break;
                        case "2":
                            mapImpact.set(field[0], { weight: val });
                            break;
                        case "3":
                            mapNeeds.set(field[0], { weight: val });
                            break;
                        default:
                            break;
                    }
                    row3Fields.push(fieldName); // ???
                } else {
                    console.log(item.id, key, fieldName); // поле, не вошедшее в перечень
                }
            }
        }
    }
    vue.$set(row1, "total", Math.round(weight));

    // second row of details
    let mark = 0; // баллы по проекту

    const row2 = { name: item.name };
    if (item.hasOwnProperty("calcResult")) {
        for (const [key, value] of Object.entries(bip?.calcResult)) {
            if (key.includes("result")) {
                const fieldName = key.substr(0, key.length - 7);
                const field = vue.detailFields.filter(function (f: any) {
                    if (f.key === fieldName) {
                        return f;
                    }
                });
                if (
                    field.length > 0 &&
                    ["1", "2", "3", "4"].includes(field[0]?.criteria_type)
                ) {
                    const val = getNumber(value);
                    vue.$set(row2, fieldName, val);
                    mark += val; // общая сумма баллов по проекту

                    switch (field[0].criteria_type) {
                        case "1":
                            const budget = mapBudget.get(field[0]);
                            if (budget !== undefined) {
                                vue.$set(budget, "mark", val);
                            } else {
                                mapBudget.set(field[0], { mark: val });
                            }
                            bipBudget += val;
                            break;
                        case "2":
                            const impact = mapImpact.get(field[0]);
                            if (impact !== undefined) {
                                vue.$set(impact, "mark", val);
                            } else {
                                mapImpact.set(field[0], { mark: val });
                            }
                            bipImpact += val;
                            break;
                        case "3":
                            const needs = mapNeeds.get(field[0]);
                            if (needs !== undefined) {
                                vue.$set(needs, "mark", val);
                            } else {
                                mapNeeds.set(field[0], { mark: val });
                            }
                            bipNeeds += val;
                            break;
                        default:
                            break;
                    }
                } else {
                    // console.log(item.id, key, fieldName);
                }
            }
            if (key === "totalResult") {
                const val = getNumber(value);
                vue.$set(item, "total", val);
                vue.$set(row2, "total", val);
            }
        }
    }

    //third row of details
    const row3 = { name: "" };
    if (bip.hasOwnProperty("criteriaIndicators")) {
        for (const [key, value] of Object.entries(bip?.criteriaIndicators)) {
            if (row3Fields.includes(key)) {
                vue.$set(row3, key, typeof value === "number" ? getNumber(value, 2) : value);
            }
        }
    }
    vue.$set(row3, "dateStateExamination", item?.dateStateExamination);
    //заполняем вложенную таблицу
    details.table.push(row1);
    details.table.push(row2);
    details.table.push(row3);

    // формируем перечень полей для отображения
    vue.$set(details, "mapBudget", mapBudget);
    vue.$set(details, "mapImpact", mapImpact);
    vue.$set(details, "mapNeeds", mapNeeds);
    // формируем перечень полей для вложенной таблицы объекта
    const detfields = [
        {
            key: "name",
            label: getTFieldTitle(vue, 'project')
        }
    ];
    for (const key of mapBudget.keys()) {
        detfields.push(key);
    }
    for (const key of mapImpact.keys()) {
        detfields.push(key);
    }
    for (const key of mapNeeds.keys()) {
        detfields.push(key);
    }
    detfields.push({ key: "total", label: getTFieldTitle(vue, 'point') });
    vue.$set(details, "fields", detfields);

    // округляем итоговые данные и передаем во внешнюю таблицу
    vue.$set(item, "budget", getNumber(bipBudget));
    vue.$set(item, "impact", getNumber(bipImpact));
    vue.$set(item, "needs", getNumber(bipNeeds));
    vue.$set(item, "details", details);
}

export function pprFinans(fin: RowNewFinanceData, period: ProjectPeriod) {
    let sum = 0;
    for (const [key, value] of Object.entries(fin)) {
        const y = parseInt(key);
        if (typeof y === "number" && period.begYear <= y && y <= period.endYear) {
            sum += getNumber(value);
        }
    }
    return sum;
} // итого суммы по newFinace

export async function forInitiator(vue: any, mode: string, bip: BipProjectDataClass, versionList: any[]) {
    let findDB = -1;
    let versions_rec: any[] = [];

    if (bip.variant_recipient !== null && bip.variant_recipient.length > 0) {
        if (bip.header.editing) {
            versions_rec = versionList.filter(row => row.attribute
                && row.year === vue.planPeriod.year
                && row.data_type === vue.selVersion.data_type
                && row.region_code === bip?.district_budget);
        } else {
            versions_rec = versionList.filter(row => row.attribute && row.variant_uuid === bip.variant_recipient);
        }

        if (versions_rec.length === 0) {
            let sms = 'Версия бюджета (' + bip.variant_recipient + ') по Получателю не актуальна:'
                + ' Период - ' + vue.planPeriod.name
                + ', Вид данных - ' + vue.selVersion.data_type
                + ', Регион бюджета - ' + bip?.district_budget;

            if (`${i18nService.locale}` === 'kk') {
                sms = 'Алушының бюджет нұсқасы (' + bip.variant_recipient + ') өзекті емес:'
                    + ' Кезең - ' + vue.planPeriod.name
                    + ', Деректер түрі - ' + vue.selVersion.data_type
                    + ', Бюджет аймағы - ' + bip?.district_budget;
            }

            if (`${i18nService.locale}` === 'en') {
                sms = 'The budget version (' + bip.variant_recipient + ') for the Recipient is not relevant:'
                    + ' Period - ' + vue.planPeriod.name
                    + ', Data type - ' + vue.selVersion.data_type
                    + ', Budget region - ' + bip?.district_budget;
            }
            if (mode === 'form') {
                vue.message(bip, sms);
            } else {
                makeToast(vue, 'danger', getSmsTitle(vue, 'attention'), sms);
            }
            versions_rec = [];
        } else {
            findDB = 0;
        }
    }

    if (bip.variant_recipient === null || bip.variant_recipient.length === 0) {

        if ([3, 4].includes(vue.selVersion.data_type)) {
            versions_rec = versionList.filter(row => row.attribute
                && row.year === vue.planPeriod.year
                && [3, 4].includes(row.data_type)
                && row.region_code === bip?.district_budget);
        } else {
            versions_rec = versionList.filter(row => row.attribute
                && row.year === vue.planPeriod.year
                && row.data_type === vue.selVersion.data_type
                && row.region_code === bip?.district_budget);
        }
        if (versions_rec.length === 0) {
            let sms = 'Отсутствует актуальная версия по Получателю:'
                + ' Период - ' + vue.planPeriod.name
                + ', Вид данных - ' + vue.selVersion.data_type
                + ', Регион бюджета - ' + bip?.district_budget;

            if (`${i18nService.locale}` === 'kk') {
                sms = 'Алушы бойынша өзекті бюджет нұсқасы жоқ:'
                    + ' Кезең - ' + vue.planPeriod.name
                    + ', Деректер түрі - ' + vue.selVersion.data_type
                    + ', Бюджет аймағы - ' + bip?.district_budget;
            }

            if (`${i18nService.locale}` === 'en') {
                sms = 'There is no current version for the Recipient:'
                    + ' Period - ' + vue.planPeriod.name
                    + ', Data type - ' + vue.selVersion.data_type
                    + ', Budget region - ' + bip?.district_budget;
            }
            if (mode === 'form') {
                vue.message(bip, sms);
            } else {
                makeToast(vue, 'danger', getSmsTitle(vue, 'attention'), sms);
            }
            versions_rec = [];
        } else {
            if (bip.id > 0) {
                await findBipVariantNull(vue, bip, bip.variant, versions_rec[0].variant_uuid)
                    .then((data: number) => {
                        findDB = data;
                        if (data && data > 0) {
                            vue.saving = false;
                            versions_rec = [];
                        }
                    });
            } else {
                findDB = 0;
            }
        }
    }

    // if ((versions_rec[0].data_type === bip.header.dataType)
    //     || (versions_rec[0].data_type === 4 && bip.header.dataType === 3)
    //     || (versions_rec[0].data_type === 3 && bip.header.dataType === 4)) {
    //     vue.$set(bip.header.regional, 'dataType', versions_rec[0].data_type);
    // }

    if (versions_rec.length > 0 && findDB === 0) {
        vue.$set(bip.header, 'dataType', vue.selVersion.data_type);
        vue.$set(bip.header.regional, 'dataType', versions_rec[0].data_type);
    }

    return {findDB: findDB, versions_rec: versions_rec};
}

export async function forRecipient(vue: any, mode: string, bip: BipProjectDataClass, versionList: any[]) {
    let findDB = -1;
    let versions: any[] = [];

    if (bip.variant !== null && bip.variant.length > 0) {
        if (bip.header.editing) {
            versions = versionList.filter(row => row.attribute
                && row.year === vue.planPeriod.year
                && row.data_type === parseInt(bip.header.dataType)
                && row.region_code === bip?.region_budget);
        } else {
            versions = versionList.filter(row => row.attribute && row.variant_uuid === bip.variant);
        }

        if (versions.length === 0) {
            let sms = 'Версия бюджета (' + String(bip.variant) + ') по Инициатору не актуальна:'
                + ' Период - ' + vue.planPeriod.name
                + ', Вид данных - ' + bip.header.dataType
                + ', Регион бюджета - ' + bip?.region_budget;

            if (`${i18nService.locale}` === 'kk') {
                sms = 'Бастамашының бюджет нұсқасы (' + bip.variant + ') өзекті емес:'
                    + ' Кезең - ' + vue.planPeriod.name
                    + ', Деректер түрі - ' + bip.header.dataType
                    + ', Бюджет аймағы - ' + bip?.region_budget;
            }

            if (`${i18nService.locale}` === 'en') {
                sms = 'Budget version (' + String(bip.variant) + ') by Initiator is not relevant:'
                    + ' Period - ' + vue.planPeriod.name
                    + ', Data Type - ' + bip.header.dataType
                    + ', Budget region - ' + bip?.region_budget;
            }
            if (mode === 'form') {
                vue.message(bip, sms);
            } else {
                makeToast(vue, 'danger', getSmsTitle(vue, 'attention'), sms);
            }
            versions = [];
        } else {
            findDB = 0;
        }
    }

    if (bip.variant === null || bip.variant.length === 0) {
        if ([3, 4].includes(vue.selVersion.data_type)) {
            versions = versionList.filter(row => row.attribute
                && row.year === vue.planPeriod.year
                && [3, 4].includes(row.data_type)
                && row.region_code === bip?.region_budget);
        } else {
            versions = versionList.filter(row => row.attribute
                && row.year === vue.planPeriod.year
                && row.data_type === vue.selVersion.data_type
                && row.region_code === bip?.region_budget);
        }

        if (versions.length === 0) {
            let sms = 'Отсутствует актуальная версия по Инициатору:'
                + ' Период - ' + vue.planPeriod.name
                + ', Вид данных - ' + vue.selVersion.data_type
                + ', Регион бюджета - ' + bip?.region_budget;

            if (`${i18nService.locale}` === 'kk') {
                sms = 'Бастамашы бойынша өзекті бюджет нұсқасы жоқ:'
                    + ' Кезең - ' + vue.planPeriod.name
                    + ', Деректер түрі - ' + vue.selVersion.data_type
                    + ', Бюджет аймағы - ' + bip?.region_budget;
            }

            if (`${i18nService.locale}` === 'en') {
                sms = 'There is no current version for the Initiator:'
                    + ' Period - ' + vue.planPeriod.name
                    + ', Data type - ' + vue.selVersion.data_type
                    + ', Budget region - ' + bip?.region_budget;
            }

            if (mode === 'form') {
                vue.message(bip, sms);
            } else {
                makeToast(vue, 'danger', getSmsTitle(vue, 'attention'), sms);
            }
            versions = [];
        } else {
            if (bip.id > 0) {
                await findBipVariantRecNull(vue, bip, versions[0].variant_uuid, bip.variant_recipient)
                    .then((data: number) => {
                        findDB = data;
                        if (data && data > 0) {
                            vue.saving = false;
                            versions = [];
                        }
                    });
            } else {
                findDB = 0;
            }
        }

        // if ((versions[0].data_type === bip.header.regional.dataType)
        //     || (versions[0].data_type === 3 && bip.header.regional.dataType === 4)
        //     || (versions[0].data_type === 4 && bip.header.regional.dataType === 3)) {
        //     vue.$set(bip.header, 'dataType', versions[0].data_type);
        // }
    }

    if (versions.length > 0 && findDB === 0) {
        vue.$set(bip.header, 'dataType', versions[0].data_type);
        vue.$set(bip.header.regional, 'dataType', vue.selVersion.data_type);
    }
    return {findDB: findDB, versions: versions};
}

export async function postData(url: string, data: any) {
    // Default options are marked with *
    try {
        const response = await fetch(url, {
            method: 'POST', // *GET, POST, PUT, DELETE, etc.
            mode: 'cors', // no-cors, *cors, same-origin
            cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
            credentials: 'same-origin', // include, *same-origin, omit
            headers: {
                'Content-Type': 'application/json'
                // 'Content-Type': 'application/x-www-form-urlencoded',
                // 'Content-Type': 'application/json;charset=utf-8'
            },
            redirect: 'follow', // manual, *follow, error
            referrerPolicy: 'no-referrer', // no-referrer, *client
            body: JSON.stringify(data) // body data type must match "Content-Type" header
        });
        return await response.json(); // parses JSON response into native JavaScript objects
    } catch (error) {
        console.log('error postData ' + url, error.toString());
        return;
    }
}

export function setCurVariant(vue: any, dt: boolean = false) {
    let vers: any[] = [];
    if (vue.planPeriod && vue.planPeriod !== null
        && vue.selRegionBudget && vue.selRegionBudget !== null
        && vue.selDataType && vue.selDataType !== null) {

        if (!dt) {
            vers = vue.versionList.filter((row: any) =>
                row.year === vue.planPeriod.year
                && row.region_code === vue.selRegionBudget.code
                && row.attribute);
            if (vers.length > 0) {
                vue.selDataType = findItem(vers[0].data_type, "code", vue.dataTypeList);
            }
        }

        vers = filterVersion(vue).filter(row => row.attribute);
        if (vers.length > 0) {
            vue.selVersion = vers[0];
        } else {
            vue.selVersion = filterVersion(vue)[0];
        }
    }
} // update version by year+region+data_type

export function checkFinDoubles(finace: RowNewFinanceData[] | RowOldFinanceData[], keys: string[]) {
    const map = new Map();

    for (const obj of finace) {
        const count = map.get(getRowKey(obj, keys));
        if (count === undefined) {
            map.set(getRowKey(obj, keys), 1);
        } else {
            map.set(getRowKey(obj, keys), count + 1);
        }
    }
    for (const val of map.values()) {
        if (val > 1) {
            return val;
        }
    }
    return 0;
} // поиск дублей по prg+ppr+spf в finace

export function checkFinNulls(finace: RowNewFinanceData[] | RowOldFinanceData[], list: any[]) {
    for (const fin of finace) {
        if (getNumber(fin.prg) === 0 || getNumber(fin.spf) === 0) {
            return 4;
        }
        const prg = findItem(fin.prg, 'prg', list);
        if (getNumber(fin.ppr) === 0 && prg.code !== null && prg.value.child.lenth > 0) {
            return 4;
        }
    }
    return 0;
}

export async function checkFinCosts(vue: any, bip: BipProjectDataClass) {
    const params = {
        'bip_code': bip.code,
        'variants': [bip.variant, bip.variant_recipient]
    }
    try {
        const response: any = await fetch(`/api-py/check-cost-data-by/${encodeURI(JSON.stringify(params))}`)
            .then((response) => response.json());

        const resMap = new Map(Object.entries(response));

        bip.newFinace.forEach((fin: RowNewFinanceData) => {
            let sum= 0;
            for (let i = 0; i < 3; i++) {
                const plan: any = resMap.get(
                    doRowKey([bip.code, bip.header.abp,
                        fin.prg, fin.ppr, fin.spf,
                        bip.header.year + i, bip.variant]));
                if (plan) {
                    sum += plan.value;
                }
            }
            vue.$set(fin, 'edited', sum > 0);
            if (sum > 0) {
                vue.$set(bip.header, 'finCost', sum > 0)
            }
        })
        if (bip.header.finalAbp === 0 && bip.header.regional) {
            bip.newFinaceRegional.forEach((fin: RowNewFinanceData) => {
                let sum = 0;
                for (let i = 0; i < 3; i++) {
                    const plan: any = resMap.get(
                        doRowKey([bip.code, bip.header.regional.abp,
                            fin.prg, fin.ppr, fin.spf,
                            bip.header.year + i, bip.variant_recipient]));
                    if (plan) {
                        sum += plan.value;
                    }
                }
                vue.$set(fin, 'edited', sum > 0);
                if (sum > 0) {
                    vue.$set(bip.header.regional, 'finCost', sum > 0);
                }
            })
        }
    } catch (error) {
        makeToast(vue, 'danger', getSmsTitle(vue, 'error_request') + ' /check-cost-data-by',
            (error as Error).toString());
    }
}

export function nameLocale(list: any[], code: string = 'code') {
    for (const item of list) {
        itemNameLocale(item, code);
    }
}

export function itemNameLocale(item: any, code: string = 'code') {
    if (item && item !== null) {
        if (item.hasOwnProperty('name_kz')) {
            item['name_kk'] = item['name_kz'];
        }

        if (item.hasOwnProperty('nameKk')) {
            item['name_kk'] = item['nameKk'];
        }

        if (item.hasOwnProperty('nameRu')) {
            item['name_ru'] = item['nameRu'];
        }

        if (!item.hasOwnProperty('name_en')) {
            item['name_en'] = item['name_ru'];
        }

        Object.defineProperty(item, 'name', {
            configurable: true,
            get: function () {
                if (!item.hasOwnProperty(`name_${i18nService.locale}`) && item.hasOwnProperty('value')
                    && typeof item.value !== 'number' && typeof item.value !== 'string') {
                    return item.value[`name_${i18nService.locale}`];
                } else {
                    return item[`name_${i18nService.locale}`];
                }
            }
        });

        if (item.hasOwnProperty(code)) {
            item['code'] = item[code];

            Object.defineProperty(item, 'text', {
                configurable: true,
                get: function () {
                    return item[code] + ' - ' + item['name'];
                }
            });
        }
    }
}

export function getCommonTitle(vue: any, field: string, id: number = -1) {
    const mainTextFieldName = field;
    if (id >= 0) return vue.$tc(mainTextFieldName, id);
    return vue.$t(mainTextFieldName);
}

export function getBipTitle(vue: any, field: string, id: number = -1) {
    const mainTextFieldName = "modules.bip." + field;
    if (id >= 0) return vue.$tc(mainTextFieldName, id);
    return vue.$t(mainTextFieldName);
}

export function getCardTitle(vue: any, sector: string, field: string) {
    return getBipTitle(vue, 'card.' + sector + '.' + field);
}

export function getFilterTitle(vue: any, field: string) {
    return getBipTitle(vue, 'filter.filter_params.' + field);
}

export function getTFieldTitle(vue: any, field: string) {
    return getBipTitle(vue, 'table_fields.' + field);
}

export function getSmsTitle(vue: any, field: string) {
    return getBipTitle(vue, 'sms.' + field);
}


